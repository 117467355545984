import {
  CommandBar,
    DetailsListLayoutMode,
    IColumn,
    Icon,
    ICommandBarItemProps,
    SelectionMode,
    ShimmeredDetailsList,
    Stack,
    Text
  } from "@fluentui/react";
  import React, { useCallback, useEffect, useMemo, useState } from "react";
  import { AccessTokens } from "../../services/API/BaseService";
import { FeatureDto, FeatureListDto, createFeature, deleteFeatures, getFeatureList, updateFeature } from "../../services/API/FeatureService";
import { FeatureEditPanel } from "./FeatureEditPanel";
import { DeleteDialog } from "./DeleteDialog";
import { IObjectWithKey, Selection } from "@fluentui/react/lib/DetailsList";
import { MarqueeSelection } from "@fluentui/react/lib/MarqueeSelection";
import { useConst } from "@fluentui/react-hooks";
  
  type FeaturesPageProps = {
    accessTokens: AccessTokens;
  };
  
  const featuresListColumns: IColumn[] = [
    {
      key: "icon",
      name: "",
      fieldName: "icon",
      minWidth: 20,
      maxWidth: 20,
      isResizable: false,
      isIconOnly: true,
      iconName: "AddFavorite",
      onRender: (item: any) => (
        <Icon iconName="AddFavorite" className="listIcon" />
      ),
    },
    {
      key: "name",
      name: "Name",
      fieldName: "name",
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
    },
    {
      key: "defaultState",
      name: "Default State",
      fieldName: "defaultState",
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
    },
    {
      key: "state",
      name: "State",
      fieldName: "state",
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
    }
  ];
  
   const FeaturesPage: React.FC<FeaturesPageProps> = ({
    accessTokens,
  }) => {
    const [featureList, setFeatureList] = useState<
      FeatureListDto | undefined
    >();
    const [features, setFeatures] = useState<FeatureDto[]>();
    const [ selectedFeature, setSelectedFeature] = useState<FeatureDto | undefined>(); 
    const [isNewFeature, setIsNewFeature] = useState<boolean>(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [selectedItems, setSelectedItems] = useState<FeatureDto[]>([]);

    const selection = useConst<Selection>(() => {
      const newSelection: Selection<FeatureDto & IObjectWithKey> = new Selection<FeatureDto & IObjectWithKey>({
        getKey: (item) => item.name,
        onSelectionChanged: () => setSelectedItems(newSelection.getSelection()),
      });
      return newSelection;
    });

    const refreshFeatureList = useCallback(() => {
      setFeatureList(undefined);
      getFeatureList(accessTokens).then((res) => {
        setFeatureList(res);
        setFeatures(res?.features ?? []);
    });
    }, [accessTokens]);

    const updateFeatureData = useCallback((data: FeatureDto) => {
       updateFeature(accessTokens, data).then(() => {
        refreshFeatureList();
        setSelectedFeature(undefined);
       });
    }, [accessTokens, refreshFeatureList]);

    const createFeatureData = useCallback((data: FeatureDto) => {
      createFeature(accessTokens, data).then(() => {
       refreshFeatureList();
       setSelectedFeature(undefined);
      });
    }, [accessTokens, refreshFeatureList]);
 
    const deleteSelectedItems = () => {
      setFeatureList(undefined);
      const names = selectedItems.map((t) => t.name);
      deleteFeatures(accessTokens, names).then(refreshFeatureList);
    };
  
    useEffect(() => {
      refreshFeatureList();
    }, [refreshFeatureList]);
  
    const commandBarItems: ICommandBarItemProps[] = useMemo(
      () => [
        {
          key: "new",
          text: "New",
          iconProps: { iconName: "Add" },
          onClick: () => {
            const feature: FeatureDto = {
              name: '',
              defaultState: false,
              state: false,
              participantIds: [],
              subscriptionIds: []
            };

            setIsNewFeature(true);
            setSelectedFeature(feature);
          },
        },
        {
          key: "reload",
          text: "Reload",
          iconProps: { iconName: "Refresh" },
          onClick: () => refreshFeatureList(),
        },
        {
          key: "delete",
          text: "Delete",
          iconProps: { iconName: "Delete" },
          onClick: () => setShowDeleteDialog(true),
          disabled: !(selectedItems?.length > 0),
        },
      ],
      [refreshFeatureList, selectedItems?.length]
    );

    return (
      <Stack className="adminPage">
         <div className="pageHeader">
          <Text variant="xLarge">Features</Text>
        </div>
        <CommandBar items={commandBarItems} className="pageCommandBar" />
        <MarqueeSelection selection={selection as Selection} isDraggingConstrainedToRoot>
          <ShimmeredDetailsList
            layoutMode={DetailsListLayoutMode.justified}
            selection={selection as Selection}
            selectionMode={SelectionMode.multiple}
            selectionPreservedOnEmptyClick={true}
            columns={featuresListColumns}
            enableShimmer={!featureList}
            shimmerLines={10} 
            items={features || []} 
            onItemInvoked={(feature) => {
              setSelectedFeature(feature);
              setIsNewFeature(false);
            }}
          />
        </MarqueeSelection>

        {selectedFeature && (
        <FeatureEditPanel
          isOpen={!!selectedFeature}
          isNew={!!isNewFeature}
          feature={selectedFeature}
          onSave={(savingData) => isNewFeature ? createFeatureData (savingData) : updateFeatureData(savingData)}
          onDismiss={() => setSelectedFeature(undefined)}
          participantOptions={featureList?.participants ?? []}
          subscriptionOptions={featureList?.subscriptions ?? []}
        ></FeatureEditPanel>)}
        {showDeleteDialog && <DeleteDialog isOpen={showDeleteDialog} onDismiss={() => setShowDeleteDialog(false)} onDelete={deleteSelectedItems} />}
      </Stack>
    );
  };

  export default FeaturesPage;