import { AccessTokens, axiosInstance } from './BaseService';
import { ParticipantSubscriptionDto } from './ParticipantService';
import { SubscriptionFullDto } from './SubscriptionService';

export type ExtendedSubscriptionFullDto = SubscriptionFullDto & {
  subscriptions: ParticipantSubscriptionDto[];
};

export const getExtendedSubscriptionInfo = (tokens: AccessTokens, subscriptionName: string) => {
  return axiosInstance
    .get(`api/subscription-participants/${subscriptionName}`, {
      headers: {
        Authorization: 'Bearer ' + tokens.application,
        apim: 'Bearer ' + tokens.apim,
      },
    })
    .then((resp) => {
      return resp.data as ExtendedSubscriptionFullDto;
    })
    .catch((error) => {
      console.log(error);
      return undefined;
    });
};

export const updateParticipntSubscriptions = (tokens: AccessTokens, subscriptions: ParticipantSubscriptionDto[]) => {
  return axiosInstance
    .put('api/subscription-participants/', subscriptions, {
      headers: {
        Authorization: 'Bearer ' + tokens.application,
        apim: 'Bearer ' + tokens.apim,
      },
    })
    .then((resp) => {
      return resp.data as boolean;
    })
    .catch((error) => {
      console.log(error);
      return undefined;
    });
};
