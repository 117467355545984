import { Stack, Text } from "@fluentui/react";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { AccessTokens } from "../../../services/API/BaseService";
import { getResponseTimebuckets, ResponseTimebucketDto } from "../../../services/API/StatisticsService";

import { XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, BarChart, Bar } from "recharts";

type CardProps = {
  accessTokens: AccessTokens;
};

export const ResponseTimebucketsCard: React.FC<CardProps> = ({ accessTokens }: CardProps) => {
  const [responseTimebuckets, setResponseTimebuckets] = useState<ResponseTimebucketDto[]>();

  useEffect(() => {
    getResponseTimebuckets(accessTokens).then(setResponseTimebuckets);
  }, [setResponseTimebuckets]);

  return (
    <Stack className="dashboardCardWrapper responseTimeTrendCard">
      <Text variant="large">Response Timebuckes</Text>
      <Stack className="dashboardCardListWrapper">
        {responseTimebuckets && responseTimebuckets.length > 0 && (
          <ResponsiveContainer width="100%" height={500}>
            <BarChart width={1200} height={300} data={responseTimebuckets} margin={{ top: 5, right: 20, bottom: 5, left: 0 }} layout="vertical">
              <XAxis dataKey="requestCount" type="number" tickCount={15} fontSize={12} />
              <YAxis dataKey="performanceBucket" type="category" width={100} fontSize={12} />
              <CartesianGrid strokeDasharray="3 3" horizontal={false} />
              <Tooltip content={<CustomTooltip />} />
              <Bar dataKey="requestCount" fill="#3e78d1" />
            </BarChart>
          </ResponsiveContainer>
        )}
      </Stack>
    </Stack>
  );
};

const CustomTooltip = ({ active, payload, label }: any) => {
  if (active && payload && payload.length) {
    return (
      <Stack className="chartTooltipWrapper">
        <Text>{label}</Text>
        <Text>{parseInt(payload[0].value)} requests</Text>
      </Stack>
    );
  }

  return null;
};
