import { AccessTokens, axiosInstance } from "./BaseService";

export enum SubscriptionDtoState {
  Suspended = 0,
  Active = 1,
  Expired = 2,
  Submitted = 3,
  Rejected = 4,
  Cancelled = 5,
}

export type SubscriptionFullDto = {
  id: string;
  name: string;
  type: string;
  notificationDate?: Date;
  endDate?: Date;
  expirationDate?: Date;
  startDate?: Date;
  createdDate?: Date;
  state: SubscriptionDtoState;
  displayName: string;
  scope: string;
  ownerId: string;
  ownerIdentifier: string;
  scopeIdentifier: string;
  stateComment: string;
  allowTracing?: boolean;
  ownerName?: string;
  ownerEmail?: string;
};

export const getSubscriptions = (tokens: AccessTokens) => {
  return axiosInstance
    .get("api/subscriptions", {
      headers: {
        Authorization: "Bearer " + tokens.application,
        apim: "Bearer " + tokens.apim,
      },
    })
    .then((resp) => {
      return resp.data as SubscriptionFullDto[];
    })
    .catch((error) => {
      console.log(error);
      return [];
    });
};

export enum ProductDtoState {
  NotPublished = 0,
  Published = 1,
}

export type ProductFullDto = {
  id: string;
  name: string;
  type: string;
  description?: string;
  terms?: string;
  subscriptionRequired?: boolean;
  approvalRequired?: boolean;
  subscriptionsLimit?: number;
  state?: ProductDtoState;
  displayName?: string;
};

export const getProducts = (tokens: AccessTokens) => {
  return axiosInstance
    .get("api/subscriptions/products", {
      headers: {
        Authorization: "Bearer " + tokens.application,
        apim: "Bearer " + tokens.apim,
      },
    })
    .then((resp) => {
      return resp.data as ProductFullDto[];
    })
    .catch((error) => {
      console.log(error);
      return [];
    });
};

export type SubscriptionUpdateDto = {
  id: string;
  scope: string;
  displayName: string;
};

export const updateSubscription = (tokens: AccessTokens, subscription: SubscriptionUpdateDto) => {
  return axiosInstance
    .put(`api/subscriptions`, subscription, {
      headers: {
        Authorization: "Bearer " + tokens.application,
        apim: "Bearer " + tokens.apim,
      },
    })
    .then((resp) => {
      return resp.data;
    })
    .catch((error) => {
      console.log(error);
    });
};
