import { createRoot } from 'react-dom/client';
import { App } from "./App";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { AuthenticationConfiguration } from "./services/API/BaseService";

const publicClientApplication = new PublicClientApplication(AuthenticationConfiguration);

const container = document.getElementById('root');
const root = createRoot(container!); 
root.render(
  <MsalProvider instance={publicClientApplication}>
    <App />
  </MsalProvider>
);
