import { DetailsListLayoutMode, IColumn, SelectionMode, ShimmeredDetailsList, Stack, Text } from "@fluentui/react";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { AccessTokens } from "../../../services/API/BaseService";
import { FailedRequestDto, getTop10FailedRequests } from "../../../services/API/StatisticsService";

const columns: IColumn[] = [
  {
    key: "name",
    name: "Name",
    fieldName: "name",
    minWidth: 200,
    isResizable: true,
  },
  {
    key: "failedCount",
    name: "Failed Count",
    fieldName: "failedCount",
    minWidth: 100,
    maxWidth: 100,
    isResizable: false,
  },
];

type CardProps = {
  accessTokens: AccessTokens;
};

export const Top10FailedRequestsCard: React.FC<CardProps> = ({ accessTokens }: CardProps) => {
  const [failedRequests, setFailedRequests] = useState<FailedRequestDto[]>();

  useEffect(() => {
    getTop10FailedRequests(accessTokens).then(setFailedRequests);
  }, [setFailedRequests]);

  return (
    <Stack className="dashboardCardWrapper top10FailedRequestsCard">
      <Text variant="large">Top 10 Failed Requests</Text>
      <Stack className="dashboardCardListWrapper">
        <ShimmeredDetailsList
          items={failedRequests || []}
          columns={columns}
          layoutMode={DetailsListLayoutMode.justified}
          selectionMode={SelectionMode.none}
          enableShimmer={!failedRequests}
          shimmerLines={10}
        />
      </Stack>
    </Stack>
  );
};
