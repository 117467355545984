import { AccessTokens, axiosInstance } from "./BaseService";

export type ParticipantSubscriptionDto = {
  id?: string;
  participantUID?: string;
  subscriptionId: string;
  certificateThumbprint: string;
  certificateExpiredAfter?: string;
  altCertificateThumbprint: string;
  altCertificateExpiredAfter?: string;
  certificateSubject?: string;
  certificateIssuer?: string;
  certificateRootThumbprint?: string;
};

export type ExtendedParticipantDto = {
  uid: string;
  name: string;
  publicKey: string;
  publicKeyXml: string;
  altPublicKey: string;
  altPublicKeyXml: string;
  supportedVersions: string;
  system: string;
  service: string;
  type: string;
  actions: string[];
  street: string;
  place: string;
  zipCode: string;
  subscriptions: ParticipantSubscriptionDto[];
};

export const getParticipants = (tokens: AccessTokens) => {
  return axiosInstance
    .get("api/participants", {
      headers: {
        Authorization: "Bearer " + tokens.application,
        apim: "Bearer " + tokens.apim,
      },
    })
    .then((resp) => {
      return (
        Array.isArray(resp.data) ? resp.data : []
      ) as ExtendedParticipantDto[];
    })
    .catch((error) => {
      console.log(error);
      return [];
    });
};

export const getParticipant = (
  tokens: AccessTokens,
  participantUid: string
) => {
  return axiosInstance
    .get(`api/participants/${participantUid}`, {
      headers: {
        Authorization: "Bearer " + tokens.application,
        apim: "Bearer " + tokens.apim,
      },
    })
    .then((resp) => {
      return resp.data as ExtendedParticipantDto;
    })
    .catch((error) => {
      console.log(error);
      return undefined;
    });
};

export const createParticipant = (
  tokens: AccessTokens,
  participant: ExtendedParticipantDto
) => {
  return axiosInstance
    .post(`api/participants`, participant, {
      headers: {
        Authorization: "Bearer " + tokens.application,
        apim: "Bearer " + tokens.apim,
      },
    })
    .then((resp) => {
      return resp.data as ExtendedParticipantDto;
    })
    .catch((error) => {
      console.log(error);
      return undefined;
    });
};

export const updateParticipant = (
  tokens: AccessTokens,
  participant: ExtendedParticipantDto
) => {
  return axiosInstance
    .put(`api/participants`, participant, {
      headers: {
        Authorization: "Bearer " + tokens.application,
        apim: "Bearer " + tokens.apim,
      },
    })
    .then((resp) => {
      return resp.data;
    })
    .catch((error) => {
      console.log(error);
      return undefined;
    });
};

export const deleteParticipant = (
  tokens: AccessTokens,
  participantUid: string
) => {
  return axiosInstance
    .delete(`api/participants/${participantUid}`, {
      headers: {
        Authorization: "Bearer " + tokens.application,
        apim: "Bearer " + tokens.apim,
      },
    })
    .then((resp) => {
      return resp.data;
    })
    .catch((error) => {
      console.log(error);
      return undefined;
    });
};
