import { DetailsListLayoutMode, IColumn, SelectionMode, ShimmeredDetailsList, Stack, Text } from "@fluentui/react";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { AccessTokens } from "../../../services/API/BaseService";
import { ExceptionCausingRequestFailureDto, getExceptionsCausingRequestFailure } from "../../../services/API/StatisticsService";

const columns: IColumn[] = [
  {
    key: "requestName",
    name: "Request Name",
    fieldName: "requestName",
    minWidth: 300,
    isResizable: true,
  },
  {
    key: "requestDuration",
    name: "Duration (ms)",
    fieldName: "requestDuration",
    minWidth: 100,
    maxWidth: 100,
    isResizable: false,
  },
];

type CardProps = {
  accessTokens: AccessTokens;
};

export const ExceptionsCausingRequestFailureCard: React.FC<CardProps> = ({ accessTokens }: CardProps) => {
  const [exceptionsCausingRequestFailure, setExceptionsCausingRequestFailure] = useState<ExceptionCausingRequestFailureDto[]>();

  useEffect(() => {
    getExceptionsCausingRequestFailure(accessTokens).then(setExceptionsCausingRequestFailure);
  }, [setExceptionsCausingRequestFailure]);

  return (
    <Stack className="dashboardCardWrapper exceptionsCausingRequestFailureCard">
      <Text variant="large">Exceptions Causing Request Failure</Text>
      <Stack className="dashboardCardListWrapper">
        <ShimmeredDetailsList
          items={exceptionsCausingRequestFailure || []}
          columns={columns}
          layoutMode={DetailsListLayoutMode.justified}
          selectionMode={SelectionMode.none}
          enableShimmer={!exceptionsCausingRequestFailure}
          shimmerLines={10}
        />
      </Stack>
    </Stack>
  );
};
