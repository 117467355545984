import { AccessTokens, axiosInstance } from "./BaseService";

export type ExceptionCausingRequestFailureDto = {
  exceptionType: string;
  failedMethod: string;
  requestDuration: number;
  requestName: string;
};

export const getExceptionsCausingRequestFailure = (tokens: AccessTokens) =>
  axiosInstance
    .get(`api/statistics/ExceptionsCausingRequestFailure`, {
      headers: {
        Authorization: "Bearer " + tokens.application,
        apim: "Bearer " + tokens.apim,
      },
    })
    .then((resp) => {
      return resp.data as ExceptionCausingRequestFailureDto[];
    })
    .catch((error) => {
      console.log(error);
      return [];
    });

export type FailedOperationDto = {
  operation_Name: string;
  failedCount: number;
  impactedUsers: number;
};

export const getFailedOperations = (tokens: AccessTokens) =>
  axiosInstance
    .get(`api/statistics/FailedOperations`, {
      headers: {
        Authorization: "Bearer " + tokens.application,
        apim: "Bearer " + tokens.apim,
      },
    })
    .then((resp) => {
      return resp.data as FailedOperationDto[];
    })
    .catch((error) => {
      console.log(error);
      return [];
    });

  export type ResponseBusinessDocuments = {
    period: Date;
    sentCount: number;
    sendFailed: number;
    retrievedCount: number;
    retrievedFailed: number;
  };

    
export const getResponseBusinessDocuments = (tokens: AccessTokens, period: string) =>
axiosInstance
  .get(`api/statistics/businessStatistics?period=` + period, {
    headers: {
      Authorization: "Bearer " + tokens.application,
      apim: "Bearer " + tokens.apim,
    },
  })
  .then((resp) => {
    return resp.data as ResponseBusinessDocuments[];
  })
  .catch((error) => {
    console.log(error);
    return [];
  });


export type ResponseTimeTrendDto = {
  avgRequestDuration: number;
  timestamp: string;
};

export const getResponseTimeTrend = (tokens: AccessTokens) =>
  axiosInstance
    .get(`api/statistics/ResponseTimeTrend`, {
      headers: {
        Authorization: "Bearer " + tokens.application,
        apim: "Bearer " + tokens.apim,
      },
    })
    .then((resp) => {
      return resp.data as ResponseTimeTrendDto[];
    })
    .catch((error) => {
      console.log(error);
      return [];
    });

export type RequestCountDto = {
  timestamp: string;
  totalCount: number;
};

export const getRequestCount = (tokens: AccessTokens) =>
  axiosInstance
    .get(`api/statistics/RequestCount`, {
      headers: {
        Authorization: "Bearer " + tokens.application,
        apim: "Bearer " + tokens.apim,
      },
    })
    .then((resp) => {
      return resp.data as RequestCountDto[];
    })
    .catch((error) => {
      console.log(error);
      return [];
    });

export type ResponseTimebucketDto = {
  performanceBucket: string;
  requestCount: number;
};

export const getResponseTimebuckets = (tokens: AccessTokens) =>
  axiosInstance
    .get(`api/statistics/ResponseTimebuckets`, {
      headers: {
        Authorization: "Bearer " + tokens.application,
        apim: "Bearer " + tokens.apim,
      },
    })
    .then((resp) => {
      return resp.data as ResponseTimebucketDto[];
    })
    .catch((error) => {
      console.log(error);
      return [];
    });

export type LastExceptionDto = {
  timestamp: Date;
  problemId: string;
  handledAt: string;
  type: string;
  message: string;
  assembly: string;
  method: string;
  outerType: string;
  outerMessage: string;
  outerAssembly: string;
  outerMethod: string;
  innermostType: string;
  innermostMessage: string;
  innermostAssembly: string;
  innermostMethod: string;
  severityLevel: number;
  details: string;
  itemType: string;
  customDimensions: string;
  customMeasurements?: any;
  operation_Name: string;
  operation_Id: string;
  operation_ParentId: string;
  operation_SyntheticSource: string;
  session_Id: string;
  user_Id: string;
  user_AuthenticatedId: string;
  user_AccountId: string;
  application_Version: string;
  client_Type: string;
  client_Model: string;
  client_OS: string;
  client_IP: string;
  client_City: string;
  client_StateOrProvince: string;
  client_CountryOrRegion: string;
  client_Browser: string;
  cloud_RoleName: string;
  cloud_RoleInstance: string;
  appId: string;
  appName: string;
  iKey: string;
  sdkVersion: string;
  itemId: string;
  itemCount: number;
  _ResourceId: string;
};

export const getLastExceptions = (tokens: AccessTokens) =>
  axiosInstance
    .get(`api/statistics/LastExceptions`, {
      headers: {
        Authorization: "Bearer " + tokens.application,
        apim: "Bearer " + tokens.apim,
      },
    })
    .then((resp) => {
      return resp.data as LastExceptionDto[];
    })
    .catch((error) => {
      console.log(error);
      return [];
    });

export type FailedRequestDto = {
  name: string;
  failedCount: number;
};

export const getTop10FailedRequests = (tokens: AccessTokens) =>
  axiosInstance
    .get(`api/statistics/Top10FailedRequests`, {
      headers: {
        Authorization: "Bearer " + tokens.application,
        apim: "Bearer " + tokens.apim,
      },
    })
    .then((resp) => {
      return resp.data as FailedRequestDto[];
    })
    .catch((error) => {
      console.log(error);
      return [];
    });
