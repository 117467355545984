import React, { useEffect, useState } from "react";
import { DetailsListLayoutMode, IColumn, IObjectWithKey, Selection, SelectionMode } from "@fluentui/react/lib/DetailsList";
import { ShimmeredDetailsList } from "@fluentui/react/lib/ShimmeredDetailsList";
import { MarqueeSelection } from "@fluentui/react/lib/MarqueeSelection";
import { AccessTokens } from "../../services/API/BaseService";
import { ReceiptFullDto, ReceiptCode, ReceiptFilterDto, getReceipts, deleteReceipts } from "../../services/API/ReceiptService";
import {
  ComboBox,
  CommandBar,
  DatePicker,
  DefaultButton,
  Dropdown,
  IComboBoxOption,
  ICommandBarItemProps,
  Icon,
  Label,
  Stack,
  TextField,
} from "@fluentui/react";
import { Text } from "@fluentui/react/lib/Text";
import { useConst } from "@fluentui/react-hooks";
import { DetailsPanel } from "./DetailsPanel";
import { DeleteDialog } from "./DeleteDialog";

const options: IComboBoxOption[] = [
  { key: 10, text: "10" },
  { key: 25, text: "25" },
  { key: 50, text: "50" },
  { key: 100, text: "100" },
  { key: 1000, text: "1k" },
  { key: 5000, text: "5k" },
  { key: 10000, text: "10k" },
  { key: 100000, text: "100k" },
];

const receiptListColumns: IColumn[] = [
  {
    key: "icon",
    name: "",
    fieldName: "icon",
    minWidth: 20,
    maxWidth: 20,
    isResizable: false,
    isIconOnly: true,
    iconName: "Page",
    onRender: (item: ReceiptFullDto) =>
      item?.code === ReceiptCode.Success ? (
        <Icon iconName="ReceiptCheck" className="documentListIcon" />
      ) : (
        <Icon iconName="ReceiptUndelivered" className="documentListIconError" />
      ),
  },
  {
    key: "id",
    name: "Id",
    fieldName: "id",
    minWidth: 220,
    maxWidth: 220,
    isResizable: true,
  },
  {
    key: "receivedDate",
    name: "Received Date",
    fieldName: "receivedDate",
    minWidth: 100,
    maxWidth: 120,
    isResizable: true,
    onRender: (item: ReceiptFullDto) => !!item.receivedDate && new Date(item.receivedDate).toLocaleString(),
  },
  {
    key: "transferId",
    name: "Transfer Id",
    fieldName: "transferId",
    minWidth: 220,
    maxWidth: 220,
    isResizable: true,
  },
  {
    key: "transferSenderId",
    name: "Transfer Sender Id",
    fieldName: "transferSenderId",
    minWidth: 220,
    maxWidth: 220,
    isResizable: true,
  },
  {
    key: "transferReceiverId",
    name: "Transfer Receiver Id",
    fieldName: "transferReceiverId",
    minWidth: 220,
    maxWidth: 220,
    isResizable: true,
  },
  {
    key: "code",
    name: "Code",
    fieldName: "code",
    minWidth: 100,
    maxWidth: 100,
    isResizable: true,
    onRender: (item: ReceiptFullDto) => ReceiptCode[item.code],
  },
  {
    key: "message",
    name: "Message",
    fieldName: "message",
    minWidth: 100,
    isResizable: true,
  },
];

type ReceiptsPageProps = {
  accessTokens: AccessTokens;
};

export const ReceiptsPage: React.FC<ReceiptsPageProps> = ({ accessTokens }: ReceiptsPageProps) => {
  const [receipts, setReceipts] = useState<ReceiptFullDto[]>();

  const [id, setId] = useState<string>();
  const [fromDate, setFromDate] = useState<Date>();
  const [toDate, setToDate] = useState<Date>();
  const [transferId, setTransferId] = useState<string>();
  const [transferSenderId, setTransferSenderId] = useState<string>();
  const [transferReceiverId, setTransferReceiverId] = useState<string>();
  const [code, setCode] = useState<ReceiptCode>();
  const [message, setMessage] = useState<string>();
  const [take, setTake] = useState<number>(options[0].key as number);

  const [pageNumber, setPageNumber] = useState<number>(1);

  const [selectedItems, setSelectedItems] = useState<ReceiptFullDto[]>([]);
  const [detailsItem, setDetailsItem] = useState<ReceiptFullDto>();
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const selection = useConst<Selection>(() => {
    const newSelection: Selection<ReceiptFullDto & IObjectWithKey> = new Selection<ReceiptFullDto & IObjectWithKey>({
      getKey: (item) => item.id,
      onSelectionChanged: () => setSelectedItems(newSelection.getSelection()),
    });
    return newSelection;
  });

  const refreshReceipts = () => {
    setReceipts(undefined);
    const filter: ReceiptFilterDto = {
      id: id,
      fromDate: fromDate,
      toDate: toDate,
      transferId: transferId,
      transferSenderId: transferSenderId,
      transferReceiverId: transferReceiverId,
      code: code,
      message: message,
      skip: take ? take * (pageNumber - 1) : undefined,
      take: take,
    };

    const cleanFilter = Object.fromEntries(Object.entries(filter).filter(([_, v]) => v !== null && v !== undefined && v !== ""));
    getReceipts(accessTokens, cleanFilter).then(setReceipts);
  };

  const commandBarItems: ICommandBarItemProps[] = [
    {
      key: "reload",
      text: "Apply Filters",
      iconProps: { iconName: "Filter" },
      onClick: () => refreshReceipts(),
    },
    {
      key: "delete",
      text: "Delete",
      iconProps: { iconName: "Delete" },
      onClick: () => setShowDeleteDialog(true),
      disabled: !(selectedItems?.length > 0),
    },
  ];

  const deleteSelectedItems = () => {
    setReceipts(undefined);
    const ids = selectedItems.map((t) => t.id);
    deleteReceipts(accessTokens, ids).then(refreshReceipts);
  };

  useEffect(() => {
    refreshReceipts();
  }, [pageNumber, take]);

  return (
    <Stack className="adminPage">
      <div className="pageHeader">
        <Text variant="xLarge">Receipts</Text>
      </div>
      <Stack horizontal tokens={{ childrenGap: 20 }}>
        <TextField label="Receipt Id" placeholder="00000000-0000-0000-0000-000000000000" value={id} onChange={(ev, text) => setId(text)} className="textGuid" />
        <TextField
          label="Document Id"
          placeholder="00000000-0000-0000-0000-000000000000"
          value={transferId}
          onChange={(ev, text) => setTransferId(text)}
          className="textGuid"
        />
        <TextField label="Document Sender" placeholder="CHE000000000" value={transferSenderId} onChange={(ev, text) => setTransferSenderId(text)}></TextField>
        <TextField
          label="Document Receiver"
          placeholder="CHE000000000"
          value={transferReceiverId}
          onChange={(ev, text) => setTransferReceiverId(text)}
        ></TextField>
        <Dropdown
          label="Code"
          selectedKey={code}
          onChange={(ev, item) => setCode(item?.key as ReceiptCode)}
          options={[
            { key: "", text: "" },
            { key: 0, text: "Success" },
            { key: 1, text: "TimeoutExpired" },
            { key: 2, text: "ValidationFailed" },
            { key: 3, text: "RejectedByReceiver" },
            { key: 4, text: "ExternalException" },
          ]}
          style={{ minWidth: "100px" }}
        />
        <TextField label="Message" value={message} onChange={(ev, text) => setMessage(text)}></TextField>
      </Stack>

      <Stack horizontal tokens={{ childrenGap: 20 }}>
        <DatePicker label="From Date" value={fromDate} onSelectDate={setFromDate as (date: Date | null | undefined) => void}></DatePicker>
        <DatePicker label="To Date" value={toDate} onSelectDate={setToDate as (date: Date | null | undefined) => void}></DatePicker>
      </Stack>

      <CommandBar items={commandBarItems} className="pageCommandBar" />

      <MarqueeSelection selection={selection as Selection} isDraggingConstrainedToRoot>
        <ShimmeredDetailsList
          items={receipts || []}
          columns={receiptListColumns}
          setKey="set"
          layoutMode={DetailsListLayoutMode.justified}
          selection={selection as Selection}
          selectionMode={SelectionMode.multiple}
          selectionPreservedOnEmptyClick={true}
          checkButtonAriaLabel="select row"
          onItemInvoked={setDetailsItem}
          enableShimmer={!receipts}
          shimmerLines={5}
        />
      </MarqueeSelection>

      <Stack horizontal tokens={{ childrenGap: 5 }} padding="20px">
        <DefaultButton text="Previous" onClick={() => setPageNumber(pageNumber - 1)} disabled={!(pageNumber > 1)} />
        <DefaultButton text="Next" onClick={() => setPageNumber(pageNumber + 1)} />
        <ComboBox defaultSelectedKey={10} options={options} onChange={(ev, option, index, value) => setTake(option?.key as number)} className="pageCounter" />
        <Label>Page {pageNumber}</Label>
      </Stack>

      {showDeleteDialog && <DeleteDialog isOpen={showDeleteDialog} onDismiss={() => setShowDeleteDialog(false)} onDelete={deleteSelectedItems} />}
      {!!detailsItem && <DetailsPanel isOpen={!!detailsItem} onDismiss={() => setDetailsItem(undefined)} item={detailsItem} />}
    </Stack>
  );
};
