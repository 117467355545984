import React, { useState } from "react";
import { DefaultButton, Dialog, DialogFooter, DialogType, PrimaryButton, TextField } from "@fluentui/react";

export type DeleteDialogProps = {
  isOpen: boolean;
  onDismiss: () => void;
  onDelete: () => void;
};

export const DeleteDialog: React.FC<DeleteDialogProps> = ({ isOpen, onDismiss, onDelete }) => {
  return (
    <Dialog
      hidden={!isOpen}
      onDismiss={onDismiss}
      dialogContentProps={{
        type: DialogType.close,
        title: "Delete Participant",
        closeButtonAriaLabel: "Close",
        subText: "Are you sure you want to delete this participant?",
      }}
    >
      <DialogFooter>
        <PrimaryButton
          onClick={() => {
            onDelete();
            onDismiss();
          }}
          text="Yes, delete"
        />
        <DefaultButton onClick={onDismiss} text="No, cancel" />
      </DialogFooter>
    </Dialog>
  );
};
