import {
  ComboBox,
  CommandBar,
  DefaultButton,
  DetailsListLayoutMode,
  IColumn,
  ICommandBarItemProps,
  Icon,
  PrimaryButton,
  SelectionMode,
  ShimmeredDetailsList,
  Stack,
  Text,
  TextField,
} from "@fluentui/react";
import React, { useCallback, useMemo, useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { AccessTokens } from "../../services/API/BaseService";
import {
  createParticipant,
  deleteParticipant,
  ExtendedParticipantDto,
  getParticipants,
  ParticipantSubscriptionDto,
  updateParticipant,
} from "../../services/API/ParticipantService";
import {
  getSubscriptions,
  SubscriptionFullDto,
} from "../../services/API/SubscriptionService";

import {
  defaultSystemOptions,
  ParticipantEditPanel,
} from "./ParticipantEditPanel";

type ParticipantsPageProps = {
  accessTokens: AccessTokens;
};

const participantListColumns: IColumn[] = [
  {
    key: "icon",
    name: "",
    fieldName: "icon",
    minWidth: 20,
    maxWidth: 20,
    isResizable: false,
    isIconOnly: true,
    iconName: "AccountBrowser",
    onRender: (item: ExtendedParticipantDto) => (
      <Icon iconName="AccountManagement" className="listIcon" />
    ),
  },
  {
    key: "name",
    name: "Name",
    fieldName: "name",
    minWidth: 100,
    maxWidth: 200,
    isResizable: true,
  },
  {
    key: "uid",
    name: "UID",
    fieldName: "uid",
    minWidth: 100,
    maxWidth: 200,
    isResizable: true,
  },
  {
    key: "publicKey",
    name: "Public Key",
    fieldName: "publicKey",
    minWidth: 100,
    isResizable: true,
    onRender: (item: ExtendedParticipantDto) => (
      <>
        {item?.publicKey === "unset"
          ? "unset"
          : item?.publicKey?.length > 64
          ? "configured"
          : "none"}
      </>
    ),
  },
  {
    key: "supportedVersions",
    name: "Versions",
    fieldName: "supportedVersions",
    minWidth: 100,
    maxWidth: 200,
    isResizable: true,
  },
  {
    key: "system",
    name: "System",
    fieldName: "system",
    minWidth: 100,
    maxWidth: 200,
    isResizable: true,
  },
  {
    key: "subscriptions",
    name: "Subscriptions",
    fieldName: "subscriptions",
    minWidth: 200,
    isResizable: true,
    onRender: (item: ExtendedParticipantDto) => (
      <>
        {item?.subscriptions
          ?.map((s: ParticipantSubscriptionDto) => s.subscriptionId)
          ?.join(", ")}
      </>
    ),
  },
];

export const ParticipantsPage: React.FC<ParticipantsPageProps> = ({
  accessTokens,
}) => {
  const [participants, setParticipants] = useState<
    ExtendedParticipantDto[] | undefined
  >();
  const [filteredParticipants, setFilteredParticipants] = useState<
    ExtendedParticipantDto[] | undefined
  >();
  const [selectedParticipant, setSelectedParticipant] = useState<
    ExtendedParticipantDto | undefined
  >();
  const [allSubscriptions, setAllSubscriptions] = useState<
    SubscriptionFullDto[]
  >([]);
  const [isNewParticipant, setIsNewParticipant] = useState<boolean>(false);
  const [systemOptions, setSystemOptions] = useState(defaultSystemOptions);
  const [system, setSystem] = useState<string>("");
  const [participantId, setParticipantId] = useState<string>("");
  const [name, setName] = useState<string>("");

  const filteredParticipantsRef = useRef<ExtendedParticipantDto[]>([]);

  const _onColumnClick = (
    ev: React.MouseEvent<HTMLElement>,
    column: IColumn
  ): void => {
    const newColumns: IColumn[] = columns.slice();
    const currColumn: IColumn = newColumns.filter(
      (currCol) => column.key === currCol.key
    )[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });
    const newItems = _copyAndSort(
      filteredParticipantsRef.current || [],
      currColumn.fieldName!,
      currColumn.isSortedDescending
    );

    setColumns(newColumns as any);
    setFilteredParticipants(newItems);
  };

  const [columns, setColumns] = useState(
    participantListColumns.map((col) => ({
      ...col,
      onColumnClick: _onColumnClick,
    }))
  );

  useEffect(() => {
    getParticipants(accessTokens).then((res) => {
      setFilteredParticipants(res);
      filteredParticipantsRef.current = res || [];
      setParticipants(res);
    });
  }, []);

  useEffect(() => {
    getSubscriptions(accessTokens).then(setAllSubscriptions);
  }, []);

  const onDeleteParticipant = (uid: string) => {
    deleteParticipant(accessTokens, uid).then(() => {
      setSelectedParticipant(undefined);
      getParticipants(accessTokens).then((res) => {
        setFilteredParticipants(res);
        filteredParticipantsRef.current = res || [];
        setParticipants(res);
      });
    });
  };

  const onSaveParticipant = (participant: ExtendedParticipantDto) => {
    (isNewParticipant
      ? createParticipant(accessTokens, participant)
      : updateParticipant(accessTokens, participant)
    ).then(() => {
      setSelectedParticipant(undefined);
      getParticipants(accessTokens).then((res) => {
        setFilteredParticipants(res);
        filteredParticipantsRef.current = res || [];
        setParticipants(res);
      });
    });
  };

  const commandBarItems: ICommandBarItemProps[] = [
    {
      key: "new",
      text: "New",
      iconProps: { iconName: "Add" },
      onClick: () => {
        const emptyParticipant: ExtendedParticipantDto = {
          uid: "",
          name: "",
          publicKey: "",
          publicKeyXml: "--will be calculated automatically--",
          altPublicKey: "",
          altPublicKeyXml: "--will be calculated automatically--",
          supportedVersions: "1.5",
          system: "",
          service: "",
          type: "",
          place: "",
          street: "",
          zipCode: "",
          actions: [],
          subscriptions: [],
        };
        setSelectedParticipant(emptyParticipant);
        setIsNewParticipant(true);
      },
    },
  ];

  const applyFilters = useCallback(() => {
    const newList = [...(participants || [])].filter(
      (participant) =>
        (!participantId && !name && !system) ||
        (participantId && participant.uid.indexOf(participantId) > -1) ||
        (name && participant.name.indexOf(name) > -1) ||
        (system && participant.system === system)
    );

    setFilteredParticipants(newList);
  }, [participants, participantId, name, system]);

  const resetFilters = useCallback(() => {
    setParticipantId("");
    setName("");
    setSystem("");
    setFilteredParticipants(participants);
    filteredParticipantsRef.current = participants || [];
  }, [participants]);

  return (
    <Stack className="adminPage">
      <div className="pageHeader">
        <Text variant="xLarge">Participants</Text>
      </div>
      <br />
      <Stack horizontal tokens={{ childrenGap: 20 }} verticalAlign="end">
        <TextField
          label="Participant Id"
          value={participantId}
          onChange={(evt, value) => setParticipantId(value || "")}
          placeholder="CHE000000000"
        />
        <TextField
          label="Name"
          value={name}
          onChange={(evt, value) => setName(value || "")}
          placeholder="Participant Name"
        />
        <ComboBox
          label="System"
          allowFreeform
          autoComplete={"on"}
          options={systemOptions}
          selectedKey={system}
          onChange={(ev, option, index, value) => {
            if (!option && value)
              setSystemOptions((prevOptions) => [
                ...prevOptions,
                { key: value, text: value },
              ]);
            setSystem(value ?? (option && (option.key as string)) ?? "");
          }}
        />
        <DefaultButton onClick={applyFilters}>Apply filters</DefaultButton>
        <DefaultButton onClick={resetFilters}>Reset filters</DefaultButton>
      </Stack>
      <CommandBar items={commandBarItems} className="pageCommandBar" />
      <ShimmeredDetailsList
        items={filteredParticipants || []}
        columns={columns}
        layoutMode={DetailsListLayoutMode.justified}
        selectionMode={SelectionMode.none}
        enableShimmer={!filteredParticipants}
        shimmerLines={10}
        onItemInvoked={(item) => {
          setSelectedParticipant(item);
          setIsNewParticipant(false);
        }}
      />
      {
        <ParticipantEditPanel
          isOpen={!!selectedParticipant}
          participant={selectedParticipant as any}
          onSave={onSaveParticipant}
          onDelete={onDeleteParticipant}
          onDismiss={() => setSelectedParticipant(undefined)}
          allSubscriptions={allSubscriptions}
          showDelete={!isNewParticipant}
        ></ParticipantEditPanel>
      }
    </Stack>
  );
};

function _copyAndSort<T>(
  items: T[],
  columnKey: string,
  isSortedDescending?: boolean
): T[] {
  const key = columnKey as keyof T;
  return items
    .slice(0)
    .sort((a: T, b: T) =>
      (isSortedDescending ? a[key] > b[key] : a[key] < b[key]) ? 1 : -1
    );
}
