import React, { useCallback, useEffect, useState } from "react";
import { DetailsListLayoutMode, IColumn, SelectionMode } from "@fluentui/react/lib/DetailsList";
import { ShimmeredDetailsList } from "@fluentui/react/lib/ShimmeredDetailsList";
import { Text } from "@fluentui/react/lib/Text";
import { AccessTokens } from "../../services/API/BaseService";
import { getLogs, LogDto, LogFilterDto } from "../../services/API/LogService";
import { Icon } from "@fluentui/react/lib/Icon";
import { ComboBox, CommandBar, DatePicker, DefaultButton, IComboBoxOption, ICommandBarItemProps, Label, Stack, TextField, TimePicker } from "@fluentui/react";
import moment from "moment";
import { NoItemDialog } from "./NoItemDialog";
import { PreviewPanel } from "./PreviewPanel";

const logListColumns: IColumn[] = [
  {
    key: "icon",
    name: "",
    fieldName: "icon",
    minWidth: 20,
    maxWidth: 20,
    isResizable: false,
    isIconOnly: true,
    onRender: (item: LogDto) => (!item.errorMessage ? null : <Icon iconName="Warning" className="documentListIconError" />),
  },
  {
    key: "receivedDate",
    name: "Received Date",
    fieldName: "receivedDate",
    minWidth: 100,
    maxWidth: 200,
    isResizable: true,
    onRender: (item: LogDto) => !!item.createdOn && new Date(item.createdOn).toLocaleString(),
  },
  {
    key: "actionName",
    name: "Action Name",
    fieldName: "actionName",
    minWidth: 100,
    maxWidth: 200,
    isResizable: true,
  },
  {
    key: "documentId",
    name: "Document Id",
    fieldName: "documentId",
    minWidth: 100,
    maxWidth: 200,
    isResizable: true,
  },
  {
    key: "receiptId",
    name: "Receipt Id",
    fieldName: "receiptId",
    minWidth: 100,
    maxWidth: 200,
    isResizable: true,
  },
  {
    key: "senderId",
    name: "Sender Id",
    fieldName: "senderId",
    minWidth: 100,
    maxWidth: 200,
    isResizable: true,
  },
  {
    key: "receiverId",
    name: "Receiver Id",
    fieldName: "receiverId",
    minWidth: 100,
    maxWidth: 200,
    isResizable: true,
  },
  {
    key: "errorMessage",
    name: "Error Message",
    fieldName: "errorMessage",
    minWidth: 100,
    maxWidth: 200,
    isResizable: true,
  },
];

const options: IComboBoxOption[] = [
  { key: 10, text: "10" },
  { key: 30, text: "30" },
  { key: 60, text: "60" },
  { key: 100, text: "100" },
];

type LogsPageProps = {
  accessTokens: AccessTokens;
};

export const LogsPage: React.FC<LogsPageProps> = ({ accessTokens }: LogsPageProps) => {
  const [logs, setLogs] = useState<LogDto[]>();

  const [filterDocumentId, setFilterDocumentId] = useState<string | undefined>();
  const [filterReceiptId, setFilterReceiptId] = useState<string | undefined>();
  const [filterFromDate, setFilterFromDate] = useState<Date | undefined>();
  const [filterFromDateTime, setFilterFromDateTime] = useState<Date | undefined>();
  const [filterToDate, setFilterToDate] = useState<Date | undefined>();
  const [filterToDateTime, setFilterToDateTime] = useState<Date | undefined>();
  const [filterSender, setFilterSender] = useState<string | undefined>();
  const [filterReceiver, setFilterReceiver] = useState<string | undefined>();
  const [filterActionName, setFilterActionName] = useState<string | undefined>();
  const [filterErrorMessage, setFilterErrorMessage] = useState<string | undefined>();
  const [filterTake, setFilterTake] = useState<number>(10);

  const [pageNumber, setPageNumber] = useState<number>(1);
  const [isNoItemDialogOpen, setIsNoItemDialogOpen] = useState<boolean>(false);
  const [selectedDocumentId, setSelectedDocumentId] = useState<string | undefined>();

  const commandBarItems: ICommandBarItemProps[] = [
    {
      key: "reload",
      text: "Apply Filters",
      iconProps: { iconName: "Filter" },
      onClick: () => refreshLogs(),
    },
  ];

  const refreshLogs = useCallback(() => {
    setLogs(undefined);

    let fromDate = filterFromDate && new Date(filterFromDate.toISOString());
    if (fromDate && filterFromDateTime) {
      let fromTime = moment(filterFromDateTime, "HH:mm");
      if (fromTime.isValid()) {
        fromDate.setHours(fromTime.hours());
        fromDate.setMinutes(fromTime.minutes());
      }
    }

    let toDate = filterToDate && new Date(filterToDate.toISOString());
    if (toDate && filterToDateTime) {
      let toTime = moment(filterToDateTime, "HH:mm");
      if (toTime.isValid()) {
        toDate.setHours(toTime.hours());
        toDate.setMinutes(toTime.minutes());
      }
    }

    const filter: LogFilterDto = {
      documentId: filterDocumentId,
      receiptId: filterReceiptId,
      fromDate: fromDate?.toISOString(),
      toDate: toDate?.toISOString(),
      sender: filterSender,
      receiver: filterReceiver,
      actionName: filterActionName,
      errorMessage: filterErrorMessage,
      skip: filterTake * (pageNumber - 1),
      take: filterTake,
    };
    const cleanFilter = Object.fromEntries(Object.entries(filter).filter(([_, v]) => v !== null && v !== undefined && v !== ""));

    getLogs(accessTokens, cleanFilter).then((newLogs) => {
      setLogs(newLogs);
    });
  }, [
    filterDocumentId,
    filterReceiptId,
    filterSender,
    filterErrorMessage,
    filterTake,
    pageNumber,
    filterActionName,
    filterFromDate,
    filterFromDateTime,
    filterReceiver,
    filterToDate,
    filterToDateTime,
  ]);

  useEffect(() => {
    refreshLogs();
  }, [pageNumber, filterTake]);

  const onLogClick = useCallback(
    (item: LogDto) => {
      if (item?.documentId) {
        setSelectedDocumentId(item?.documentId);
      } else {
        setIsNoItemDialogOpen(true);
      }
    },
    [setSelectedDocumentId, setIsNoItemDialogOpen]
  );

  return (
    <Stack className="adminPage">
      <div className="pageHeader">
        <Text variant="xLarge">Document Logs</Text>
      </div>
      <Stack horizontal tokens={{ childrenGap: 20 }}>
        <TextField
          label="Document Id"
          placeholder="00000000-0000-0000-0000-000000000000"
          value={filterDocumentId}
          onChange={(ev, text) => setFilterDocumentId(text)}
          className="textGuid"
        />
        <TextField
          label="Receipt Id"
          placeholder="00000000-0000-0000-0000-000000000000"
          value={filterReceiptId}
          onChange={(ev, text) => setFilterReceiptId(text)}
          className="textGuid"
        />
        <TextField label="Sender" placeholder="CHE000000000" value={filterSender} onChange={(ev, text) => setFilterSender(text)}></TextField>
        <TextField label="Receiver" placeholder="CHE000000000" value={filterReceiver} onChange={(ev, text) => setFilterReceiver(text)}></TextField>
        <TextField label="Action Name" value={filterActionName} onChange={(ev, text) => setFilterActionName(text)}></TextField>
        <TextField label="Error Message" value={filterErrorMessage} onChange={(ev, text) => setFilterErrorMessage(text)}></TextField>
      </Stack>

      <Stack horizontal tokens={{ childrenGap: 20 }}>
        <DatePicker label="From Date" value={filterFromDate} onSelectDate={setFilterFromDate as (date: Date | null | undefined) => void}></DatePicker>
        <TimePicker
          label="From Time"
          defaultValue={filterFromDateTime}
          onChange={(ev, value) => setFilterFromDateTime(value)}
        ></TimePicker> 
        <DatePicker label="To Date" value={filterToDate} onSelectDate={setFilterToDate as (date: Date | null | undefined) => void}></DatePicker>
        <TimePicker
          label="To Time"
          defaultValue={filterToDateTime}
          onChange={(ev, value) => setFilterToDateTime(value)}
        ></TimePicker>
      </Stack>
      <CommandBar items={commandBarItems} className="pageCommandBar" />
      <ShimmeredDetailsList
        items={logs || []}
        columns={logListColumns}
        setKey="set"
        layoutMode={DetailsListLayoutMode.justified}
        selectionMode={SelectionMode.none}
        selectionPreservedOnEmptyClick={true}
        ariaLabelForSelectionColumn="Toggle selection"
        ariaLabelForSelectAllCheckbox="Toggle selection for all items"
        checkButtonAriaLabel="select row"
        enableShimmer={!logs}
        shimmerLines={filterTake}
        onItemInvoked={onLogClick}
      />
      <Stack horizontal tokens={{ childrenGap: 5 }}>
        <DefaultButton text="Previous" onClick={() => setPageNumber(pageNumber - 1)} disabled={!(pageNumber > 1)} />
        <DefaultButton text="Next" onClick={() => setPageNumber(pageNumber + 1)} />
        <ComboBox
          defaultSelectedKey={10}
          options={options}
          onChange={(ev, option, index, value) => setFilterTake(option?.key as number)}
          className="pageCounter"
        />
        <Label>Page {pageNumber}</Label>
      </Stack>
      <NoItemDialog isOpen={isNoItemDialogOpen} onDismiss={() => setIsNoItemDialogOpen(false)}></NoItemDialog>
      {selectedDocumentId && (
        <PreviewPanel
          isOpen={!!selectedDocumentId}
          documentId={selectedDocumentId as string}
          onDismiss={() => setSelectedDocumentId(undefined)}
          accessTokens={accessTokens}
        ></PreviewPanel>
      )}
    </Stack>
  );
};
