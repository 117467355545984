import React, { useEffect, useState, useCallback } from 'react';
import { AccessTokens } from '../../services/API/BaseService';
import { getSubscriptions, SubscriptionFullDto, ProductFullDto, getProducts, SubscriptionUpdateDto, updateSubscription } from '../../services/API/SubscriptionService';
import { ComboBox, DefaultButton, IDropdownOption, Pivot, PivotItem, Separator, Spinner, Stack, TextField } from '@fluentui/react';
import { Text } from '@fluentui/react/lib/Text';
import { ExtendedSubscriptionFullDto, getExtendedSubscriptionInfo, updateParticipntSubscriptions } from '../../services/API/SubcriptionParticipantsService';
import { useParams } from 'react-router-dom';
import { SubscriptionsEditor } from '../../components';
import { ParticipantSubscriptionDto } from '../../services/API/ParticipantService';

type SubscriptionParticipantsPageProps = {
  accessTokens: AccessTokens;
};

export const SubscriptionParticipantsPage: React.FC<SubscriptionParticipantsPageProps> = ({ accessTokens }: SubscriptionParticipantsPageProps) => {
  const [subscriptionInfo, setSubscription] = useState<ExtendedSubscriptionFullDto>();
  const [productNameItems, setProductNameItems] = useState<IDropdownOption[]>([]);
  const [selectedItem, setSelectedItem] = useState<IDropdownOption>();
  const [displayName, setDisplayName] = useState<string>();
  const [products, setProducts] = useState<ProductFullDto[]>();
  const [subscriptions, setSubscriptions] = useState<SubscriptionFullDto[]>([]);

  const refreshSubscriptions = useCallback(() => {
    setSubscriptions([]);
    getSubscriptions(accessTokens).then(setSubscriptions);
  }, [setSubscriptions, accessTokens]);

  const params = useParams<{ id: string }>();

  const onUpdateSubscription = useCallback(() => {
    if (subscriptionInfo && selectedItem) {
      const request: SubscriptionUpdateDto = {
        id: subscriptionInfo.name,
        scope: selectedItem.key as string,
        displayName: displayName || '',
      };
      updateSubscription(accessTokens, request).then(() => {});
    }
  }, [subscriptionInfo, selectedItem, updateSubscription, accessTokens, displayName]);

  const onUpdateParticipantSubscriptions = useCallback(
    async (subs: ParticipantSubscriptionDto[]) => {
      await updateParticipntSubscriptions(accessTokens, subs);
      setSubscription((sub: any) => {
        if (!sub) return undefined;

        const nweSub: ExtendedSubscriptionFullDto = { ...sub };

        nweSub.subscriptions = nweSub.subscriptions.map((ps) => {
          const updatedSub = subs.find((us) => us.id === ps.id);

          if (updatedSub) return updatedSub;

          return ps;
        });

        return nweSub;
      });
    },
    [setSubscription],
  );

  useEffect(() => {
    async function fetchInfo() {
      const subscription = await getExtendedSubscriptionInfo(accessTokens, params.id);
      setSubscription(subscription);
      setDisplayName(subscription?.displayName);
    }
    fetchInfo();
    refreshSubscriptions();
  }, []);

  useEffect(() => {
    getProducts(accessTokens).then(setProducts);
  }, [accessTokens]);

  useEffect(() => {
    products &&
      setProductNameItems(
        products.map((s) => ({
          key: s.id,
          text: s.displayName ?? s.name ?? s.id,
        })),
      );
  }, [products, setProductNameItems]);

  useEffect(() => {
    if (productNameItems?.length > 0 && !!subscriptionInfo?.scope) {
      const itemProduct = productNameItems.find((p) => p.key === subscriptionInfo.scope);
      itemProduct && setSelectedItem(itemProduct);
    }
  }, [productNameItems, subscriptionInfo, setDisplayName]);

  return (
    <Stack className="adminPage">
      <div className="pageHeader">
        <Text variant="xLarge">Subscription details</Text>
      </div>
      {!subscriptionInfo ? (
        <Spinner />
      ) : (
        <>
          <ComboBox
            label="Scope (Product) Id"
            selectedKey={selectedItem ? selectedItem.key : undefined}
            onChange={(_, item) => {
              setSelectedItem(item);
            }}
            placeholder="Scope (Product) Id"
            options={productNameItems}
            multiSelect={false}
          />
          <TextField label="Display Name" value={displayName} onChange={(_, text) => setDisplayName(text)} />
          <br />

          <DefaultButton text="Update info" onClick={onUpdateSubscription} disabled={!selectedItem}></DefaultButton>
          <Separator />
          <Pivot>
            <PivotItem headerText="Information">
              <Stack horizontal wrap gap="16">
                {!!subscriptionInfo &&
                  Object.keys(subscriptionInfo)
                    .filter((key) => key !== 'subscriptions')
                    .map((key) => (
                      <Stack.Item style={{ width: '32%' }}>
                        <TextField key={key} label={key} borderless value={(subscriptionInfo as any)[key]} />
                      </Stack.Item>
                    ))}
              </Stack>
            </PivotItem>
            <PivotItem headerText="Participants">
              <Stack>
                <SubscriptionsEditor
                  isSelectionEnabled
                  availableSubscriptions={subscriptions}
                  onUpdateSubsciptions={onUpdateParticipantSubscriptions}
                  displayParticipantUid
                  subscriptionId={params.id}
                  subscriptions={subscriptionInfo.subscriptions}
                />
              </Stack>
            </PivotItem>
          </Pivot>
        </>
      )}
    </Stack>
  );
};
