import { AccessTokens, axiosInstance } from "./BaseService";

export enum TransferState {
  Pending = 0,
  Sent = 1,
}

export type DocumentFilterDto = {
  id?: string;
  fromDate?: Date;
  toDate?: Date;
  senderId?: string;
  receiverId?: string;
  documentType?: string;
  state?: TransferState;
  encryptionType?: string;
  skip?: number;
  take?: number;
};

export type DocumentFullDto = {
  id: string;
  createdOn: Date;
  senderId: string;
  receiverId: string;
  documentType: string;
  state: TransferState;
  encryptionType: string;
  encryptionProps: string;
  signature: string;
};

export const getDocuments = (tokens: AccessTokens, filter: DocumentFilterDto) => {
  return axiosInstance
    .post("api/documents/list", filter, {
      headers: {
        Authorization: "Bearer " + tokens.application,
        apim: "Bearer " + tokens.apim,
      },
    })
    .then((resp) => {
      return resp.data as DocumentFullDto[];
    })
    .catch((error) => {
      console.log(error);
      return [];
    });
};

export const deleteDocuments = (tokens: AccessTokens, ids: string[]) => {
  return axiosInstance
    .delete("api/documents", {
      headers: {
        Authorization: "Bearer " + tokens.application,
        apim: "Bearer " + tokens.apim,
      },
      data: ids,
    })
    .then((resp) => {
      return resp.data;
    })
    .catch((error) => {
      console.log(error);
    });
};
