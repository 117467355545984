import React from "react";
import { DefaultButton, Dialog, DialogFooter, DialogType } from "@fluentui/react";

export type NoItemDialogProps = {
  isOpen: boolean;
  onDismiss: () => void;
};

export const NoItemDialog: React.FC<NoItemDialogProps> = ({ isOpen, onDismiss }) => {
  return (
    <Dialog
      hidden={!isOpen}
      onDismiss={onDismiss}
      dialogContentProps={{
        type: DialogType.close,
        title: "No preview",
        closeButtonAriaLabel: "Close",
        subText: "The log record you have selected does not contain a Document Id. Only records with Document Id can be previewed.",
      }}
      modalProps={{
        isBlocking: false,
        styles: { main: { maxWidth: 450 } },
      }}
    >
      <DialogFooter>
        <DefaultButton onClick={onDismiss} text="OK" />
      </DialogFooter>
    </Dialog>
  );
};
