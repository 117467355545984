import { ComboBox, DefaultButton, IComboBoxOption, Panel, PanelType, PrimaryButton, Separator, Stack, TextField, Toggle } from '@fluentui/react';
import React, { useState } from 'react';
import { FeatureDto } from '../../services/API/FeatureService';

type FeatureEditPanelProps = {
  feature: FeatureDto;
  onSave: (feature: FeatureDto) => void;
  onDismiss: () => void;
  isNew: boolean;
  isOpen: boolean;
  subscriptionOptions: IComboBoxOption[];
  participantOptions: IComboBoxOption[];
};

export const FeatureEditPanel: React.FC<FeatureEditPanelProps> = ({ feature, isNew, onSave,  onDismiss,  isOpen, subscriptionOptions, participantOptions }) => {
  const [ featureName, setFeatureName ] = useState<string>(feature.name);
  const [ defaultState, setDefaultState ] = useState<boolean>(feature.defaultState ?? false);
  const [ state, setState ] = useState<boolean>(feature.state ?? false);
  const [ selectedSubscriptionIds, setSelectedSubscriptionIds] = useState<string[]>(feature.subscriptionIds ?? []);
  const [ selectedParticipantIds, setSelectedParticipantIds] = useState<string[]>(feature.participantIds ?? []);

  const onSaveClick = () => {
    onSave({
        name:  featureName,
        state: state,
        defaultState: defaultState,
        participantIds: selectedParticipantIds,
        subscriptionIds: selectedSubscriptionIds
    });
  };

  return (
    <Panel
      type={PanelType.large}
      headerText={!isNew ? `Edit Feature` : 'Create feature'}
      onDismiss={onDismiss}
      isOpen={isOpen}
      closeButtonAriaLabel="Close"
      onRenderFooterContent={() => (
        <>
          <Separator />
          <Stack horizontal tokens={{ childrenGap: 10 }}>
            <PrimaryButton onClick={onSaveClick}>Save</PrimaryButton>
            <DefaultButton onClick={onDismiss}>Cancel</DefaultButton>
          </Stack>
        </>
      )}
    >
          <TextField label={'Name'} value={featureName} onChange={(ev, text) => setFeatureName(text || '')} readOnly={!isNew}/>
          <Toggle
            label="Default State"
            checked={defaultState}
            onText="Enabled by default"
            offText="Disabled by default"
            onChange={(ev, checked) => setDefaultState(!!checked)}
          />          
          <Toggle
            label="State"
            checked={state}
            onText="Enabled"
            offText="Disabled"
            onChange={(ev, checked) => setState(!!checked)}
          />
          <ComboBox
            label="Access by subscriptions"
            allowFreeform={false}
            options={subscriptionOptions}
            multiSelect
            selectedKey={selectedSubscriptionIds}
            onChange={(ev, option) => {
              option && setSelectedSubscriptionIds(
                option?.selected ? 
                [...selectedSubscriptionIds, option!.key as string] : 
                selectedSubscriptionIds.filter((k) => k !== option!.key));
            }}
          />
          <ComboBox
            label="Access by participants"
            allowFreeform={false}
            options={participantOptions}
            multiSelect
            selectedKey={selectedParticipantIds}
            onChange={(ev, option) => {
              option && setSelectedParticipantIds(
                option?.selected ? 
                [...selectedParticipantIds, option!.key as string] : 
                selectedParticipantIds.filter((k) => k !== option!.key));
            }}
          /> 
    </Panel>
  );
};
