import { Stack, Text } from "@fluentui/react";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { AccessTokens } from "../../../services/API/BaseService";
import { ResponseTimeTrendDto, getResponseTimeTrend } from "../../../services/API/StatisticsService";

import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from "recharts";
import moment from "moment";

type CardProps = {
  accessTokens: AccessTokens;
};

export const ResponseTimeTrendCard: React.FC<CardProps> = ({ accessTokens }: CardProps) => {
  const [responseTimeTrends, setResponseTimeTrends] = useState<ResponseTimeTrendDto[]>();

  useEffect(() => {
    getResponseTimeTrend(accessTokens).then((d) => {
      let stats = Array(24)
        .fill(1)
        .map((x, y) => {
          let date = new Date();
          date.setHours(date.getHours() - y);
          date.setMinutes(0);

          let tstamp = d.find((x) => {
            let st = moment(x.timestamp).toDate();
            return st.getDay() == date.getDay() && st.getHours() == date.getHours();
          });
          return {
            timestamp: date,
            avgRequestDuration: tstamp ? tstamp.avgRequestDuration : 0,
          };
        });

      stats.sort((o1, o2) => {
        if (o1.timestamp < o2.timestamp) return -1;
        else if (o1.timestamp > o2.timestamp) return 1;
        else return 0;
      });

      stats = stats.map((d) => {
        return { timestamp: +moment(d.timestamp).toDate(), avgRequestDuration: d.avgRequestDuration };
      }) as any;

      setResponseTimeTrends(stats as any);
    });
  }, [setResponseTimeTrends]);

  return (
    <Stack className="dashboardCardWrapper responseTimeTrendCard">
      <Text variant="large">Response Time Trend</Text>
      <Stack className="dashboardCardListWrapper">
        <ResponsiveContainer width="100%" height={300}>
          <LineChart width={1200} height={300} data={responseTimeTrends} margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
            <XAxis dataKey="timestamp" tickFormatter={dateFormatter} fontSize={12} />
            <YAxis fontSize={12} unit="ms" />
            <CartesianGrid strokeDasharray="3 3" />
            <Tooltip content={<CustomTooltip />} />
            <Line type="monotone" dataKey="avgRequestDuration" stroke="#3e78d1" dot strokeWidth="3" isAnimationActive={false} />
          </LineChart>
        </ResponsiveContainer>
      </Stack>
    </Stack>
  );
};

const dateFormatter = (item: any) => moment(item).format("H:mm");

const CustomTooltip = ({ active, payload, label }: any) => {
  if (active && payload && payload.length) {
    return (
      <Stack className="chartTooltipWrapper">
        <Text>{moment(label).format("H:mm")}</Text>
        <Text>{parseInt(payload[0].value)}ms</Text>
      </Stack>
    );
  }

  return null;
};
