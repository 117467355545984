import React from "react";
import { Panel, TextField } from "@fluentui/react";

export const DetailsPanel: React.FC<{
  isOpen: boolean;
  item?: any;
  onDismiss: () => void;
}> = ({ isOpen, onDismiss, item }) => {
  return (
    <Panel headerText={`Receipt Metadata`} isOpen={isOpen} onDismiss={onDismiss} closeButtonAriaLabel="Close" isLightDismiss>
      <div>{!!item && Object.keys(item).map((key) => <TextField label={key} borderless value={item[key]} />)}</div>
    </Panel>
  );
};
