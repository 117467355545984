import React from "react";
import { Label, Panel, PanelType, Text } from "@fluentui/react";

export const DetailsPanel: React.FC<{
  isOpen: boolean;
  item?: any;
  onDismiss: () => void;
}> = ({ isOpen, onDismiss, item }) => {
  return (
    <Panel headerText={`Item Metadata`} isOpen={isOpen} onDismiss={onDismiss} closeButtonAriaLabel="Close" isLightDismiss type={PanelType.large}>
      <div style={{ whiteSpace: "pre-line" }}>
        {!!item &&
          Object.keys(item).map((key, i) => (
            <>
              <Label>{key}</Label>
              <Text key={i}>{item[key]}</Text>
            </>
          ))}
      </div>
    </Panel>
  );
};
