import { ComboBox, IDropdownOption, Stack, Text } from "@fluentui/react";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { AccessTokens } from "../../../services/API/BaseService";
import { ResponseBusinessDocuments, getResponseBusinessDocuments } from "../../../services/API/StatisticsService";

import { XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, BarChart, Bar, Legend, Rectangle, Label } from "recharts";
import moment from "moment";

type CardProps = {
  accessTokens: AccessTokens;
};

export const BusinessDocumentCard: React.FC<CardProps> = ({ accessTokens }: CardProps) => {
  const defaultSelectedItem = { key: 0, text: "Hourly" } as IDropdownOption;

  const [businessDocuments, setBusinessDocuments] = useState<ResponseBusinessDocuments[]>();
  const [selectedPeriod, setSelectedPeriod] = useState<IDropdownOption>(defaultSelectedItem);  
  const periodOptions: IDropdownOption[] = ([
    defaultSelectedItem,
    { key: 1, text: "Daily" },
    { key: 2, text: "Mountly" },
    { key: 3, text: "Yearly" },
  ]);
  
  useEffect(() => {
    getResponseBusinessDocuments(accessTokens, selectedPeriod.key as string).then((d) => {
      let stats = Array(0)
      const now = new Date();
      let date = new Date(now);
  
      switch (selectedPeriod.key) {
        case 0: // Hourly
          stats = Array.from({ length: 24 }).map((_, y) => {
            const hourDate = new Date(date);
            hourDate.setHours(now.getHours() - y);
            hourDate.setMinutes(0);
            hourDate.setSeconds(0);
            hourDate.setMilliseconds(0);
  
            const data = d.find((x) => {
              const st = moment(x.period).toDate();
              return st.getHours() === hourDate.getHours() && st.getDay() === hourDate.getDay();
            });
  
            return generateBusinessDocument(hourDate, data);
          });
          break;
        case 1: // Daily
          stats = Array.from({ length: 30 }).map((_, y) => {
            const dayDate = new Date(date);
            dayDate.setDate(now.getDate() - y);
            dayDate.setHours(0, 0, 0, 0);
  
            const data = d.find((x) => {
              const st = moment(x.period).toDate();
              return st.getDate() === dayDate.getDate() && st.getMonth() === dayDate.getMonth();
            });

            return generateBusinessDocument(dayDate, data);
          });
          break;
        case 2: // Monthly
          stats = Array.from({ length: 12 }).map((_, y) => {
            const monthDate = new Date(date);
            monthDate.setMonth(now.getMonth() - y);
            monthDate.setDate(1);
            monthDate.setHours(0, 0, 0, 0);
  
            const data = d.find((x) => {
              const st = moment(x.period).toDate();
              return st.getMonth() === monthDate.getMonth() && st.getFullYear() === monthDate.getFullYear();
            });
  
            return generateBusinessDocument(monthDate, data);
          });
          break;
        case 3: // Yearly
          stats = Array.from({ length: d.length }).map((_, y) => {
            const yearDate = new Date(date);
            yearDate.setFullYear(now.getFullYear() - y);
            yearDate.setMonth(0);
            yearDate.setDate(1);
            yearDate.setHours(0, 0, 0, 0);
  
            const data = d.find((x) => {
              const st = moment(x.period).toDate();
              return st.getFullYear() === yearDate.getFullYear();
            });
  
            return generateBusinessDocument(yearDate, data);
          });
          break;
        default:
          break;
      }
  
      stats.sort((o1, o2) => {
        if (o1.period < o2.period) return -1;
        else if (o1.period > o2.period) return 1;
        else return 0;
      });
  
      setBusinessDocuments(stats as ResponseBusinessDocuments[]);
    });
  }, [accessTokens, selectedPeriod]);

  function generateBusinessDocument(date: Date, obj: ResponseBusinessDocuments | undefined)
  {
    return {
      period: date,
      sentCount: obj ? obj.sentCount : 0,
      sendFailed: obj ? obj.sendFailed : 0,
      retrievedCount: obj ? obj.retrievedCount : 0,
      retrievedFailed: obj ? obj.retrievedFailed : 0,
    };
  }
  

  return (
    <Stack className="dashboardCardWrapper">
      <Stack horizontal horizontalAlign="start" tokens={{ childrenGap: 20 }}>
          <Text variant="large">Transfer document status</Text>
          <ComboBox
              selectedKey={selectedPeriod ? selectedPeriod.key : undefined}
              onChange={(_, item) => {
                if (item)
                  setSelectedPeriod(item);
              }}
              placeholder="Period"
              options={periodOptions}
              multiSelect={false}
            />
      </Stack>
      <Stack className="dashboardCardListWrapper">
        <ResponsiveContainer width="100%" height={290}>
          <BarChart
            width={500}
            height={290}
            data={businessDocuments}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="period" tickFormatter={(tick) => dateFormatter(tick, selectedPeriod.key as number)} fontSize={12} />
            <Tooltip content={<CustomTooltip period={selectedPeriod.key} />} />
            <YAxis />
            <Legend content={<CustomLegend />} />
            <Bar dataKey="sentCount" fill="#4CAF50" />
            <Bar dataKey="sendFailed" fill="#F44336" /> 
            <Bar dataKey="retrievedCount" fill="#2196F3" /> 
            <Bar dataKey="retrievedFailed" fill="#FF9800" /> 
          </BarChart>
        </ResponsiveContainer>
      </Stack>
    </Stack>
  );
};

const dateFormatter = (item: any, period: number) => {
  const date = moment(item);
  
  switch (period) {
    case 0: // Hourly
      return date.format("HH:mm");
    case 1: // Daily
      return date.format("MMMM DD");
    case 2: // Monthly
      return date.format("MMMM YYYY");
    case 3: // Yearly
      return date.format("YYYY");
    default:
      return date.format("MMMM DD, HH:mm");
  }
};

const CustomTooltip = ({ active, payload, label, period }: any) => {
  if (active && payload && payload.length) {
    return (
      <Stack className="chartTooltipWrapper">
        <Text>{dateFormatter(label, period)}</Text>
        <Text>Sent documents: {payload[0].value}</Text>
        <Text>Send failed: {payload[1].value}</Text>
        <Text>Retrieved documents: {payload[2].value}</Text>
        <Text>Retrieve failed: {payload[3].value}</Text>
      </Stack>
    );
  }

  return null;
};

const CustomLegend = ({ payload }: any) => {
  if (payload && payload.length) {
    return (
      <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: 5 }}>
        <Stack horizontal verticalAlign="center">
          <div style={{ width: 10, height: 10, backgroundColor: "#4CAF50", marginRight: 5 }}></div>
          <Text>Sent documents</Text>
        </Stack>
        <Stack horizontal verticalAlign="center">
          <div style={{ width: 10, height: 10, backgroundColor: "#F44336", marginRight: 5 }}></div>
          <Text>Send failed</Text>
        </Stack>
        <Stack horizontal verticalAlign="center">
          <div style={{ width: 10, height: 10, backgroundColor: "#2196F3", marginRight: 5 }}></div>
          <Text>Retrieved documents</Text>
        </Stack>
        <Stack horizontal verticalAlign="center">
          <div style={{ width: 10, height: 10, backgroundColor: "#FF9800", marginRight: 5 }}></div>
          <Text>Retrieve failed</Text>
        </Stack>
      </Stack>
    );
  }

  return null;
};