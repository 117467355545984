import React from "react";
import { DefaultButton, Dialog, DialogFooter, DialogType, PrimaryButton } from "@fluentui/react";

export const DeleteDialog: React.FC<{
  isOpen: boolean;
  onDismiss: () => void;
  onDelete: () => void;
}> = ({ isOpen, onDismiss, onDelete }) => (
  <Dialog
    hidden={!isOpen}
    onDismiss={onDismiss}
    dialogContentProps={{
      type: DialogType.close,
      title: "Delete Documents",
      closeButtonAriaLabel: "Close",
      subText: "Do you want to delete these documents?",
    }}
  >
    <DialogFooter>
      <PrimaryButton
        onClick={() => {
          onDelete();
          onDismiss();
        }}
        text="Delete"
      />
      <DefaultButton onClick={onDismiss} text="Cancel" />
    </DialogFooter>
  </Dialog>
);
