import { Selection, Stack } from '@fluentui/react';
import React, { useMemo, useCallback, useState, useRef } from 'react';

import { ParticipantSubscriptionDto } from '../services/API/ParticipantService';
import { SubscriptionFullDto } from '../services/API/SubscriptionService';
import { SubscriptionsDetailList } from './SubscriptionDetailsList';
import { SubscriptionEditBox, SubscriptionEditBoxRefProps } from './SubscriptionEditBox';

type SubscriptionsEditorProps = {
  subscriptions: ParticipantSubscriptionDto[];
  availableSubscriptions: SubscriptionFullDto[];
  displayActions?: boolean;
  subscriptionId?: string;
  participantUid?: string;
  onCreateSubscription?: (subscriptions: ParticipantSubscriptionDto) => void;
  onUpdateSubsciptions: (subscriptions: ParticipantSubscriptionDto[]) => void;
  onDeleteSubscription?: (subscription: ParticipantSubscriptionDto) => void;
  displayParticipantUid?: boolean;
  isSelectionEnabled?: boolean;
  displaySubscriptionId?: boolean;
};

export const SubscriptionsEditor: React.FC<SubscriptionsEditorProps> = ({
  subscriptions,
  availableSubscriptions,
  participantUid,
  subscriptionId,
  onUpdateSubsciptions,
  displayActions,
  isSelectionEnabled,
  onCreateSubscription,
  onDeleteSubscription,
  displayParticipantUid,
  displaySubscriptionId,
}) => {
  const selection = useMemo(
    () =>
      new Selection<ParticipantSubscriptionDto>({
        getKey: (x) => x.participantUID || '',
      }),
    [],
  );

  const selectionEdixBoxRef: React.Ref<SubscriptionEditBoxRefProps> = useRef<SubscriptionEditBoxRefProps>(null);

  const [selectedSubscription, setSelectedSubscription] = useState<ParticipantSubscriptionDto>();

  const onUpdateClick = useCallback(
    (certificateThumbprint: string = '', certificateExpiredAfter: string | undefined, certificateRootThumbprint: string, certificateIssuer: string, certificateSubject: string) => {
      if (selectedSubscription) {
        onUpdateSubsciptions([
          {
            ...selectedSubscription,

            certificateThumbprint,
            certificateExpiredAfter,
            certificateRootThumbprint,
            certificateIssuer,
            certificateSubject,

            altCertificateThumbprint: selectedSubscription.certificateThumbprint,
            altCertificateExpiredAfter: selectedSubscription.certificateExpiredAfter,
          },
        ]);
        setSelectedSubscription(undefined);
        return;
      }

      const selections = selection.getSelection();

      const newSubscriptions = selections.map((sub) => {
        return {
          ...sub,
          certificateThumbprint,
          certificateExpiredAfter,
          certificateRootThumbprint,
          certificateIssuer,
          certificateSubject,

          altCertificateThumbprint: sub.certificateThumbprint,
          altCertificateExpiredAfter: sub.certificateExpiredAfter,
        };
      });

      onUpdateSubsciptions(newSubscriptions);
    },
    [onUpdateSubsciptions, selectedSubscription],
  );

  const onCreateClick = useCallback(
    (
      selectedSubscription: string,
      certificateThumbprint: string = '',
      certificateExpiredAfter: string | undefined,
      certificateRootThumbprint: string,
      certificateIssuer: string,
      certificateSubject: string,
    ) => {
      onCreateSubscription &&
        onCreateSubscription({
          subscriptionId: selectedSubscription,
          certificateThumbprint,
          certificateExpiredAfter,
          certificateRootThumbprint,
          certificateIssuer,
          certificateSubject,
          participantUID: participantUid,
        } as ParticipantSubscriptionDto);
    },
    [onCreateSubscription],
  );

  const onCancelClick = useCallback(() => setSelectedSubscription(undefined), []);

  return (
    <Stack>
      <SubscriptionsDetailList
        selection={isSelectionEnabled ? selection : undefined}
        displayParticipantUid={displayParticipantUid}
        subscriptions={subscriptions}
        displayActions={displayActions}
        onEdit={(sub) => {
          setSelectedSubscription(sub);
          selectionEdixBoxRef.current?.setValues(sub);
        }}
        displaySubscriptionId={displaySubscriptionId}
        onDelete={onDeleteSubscription}
      />
      <SubscriptionEditBox
        ref={selectionEdixBoxRef}
        availableSubscriptions={availableSubscriptions}
        subscriptionId={selectedSubscription?.subscriptionId || subscriptionId}
        onUpdate={onUpdateClick}
        onCreate={onCreateClick}
        onCancel={onCancelClick}
        participantUid={participantUid}
      />
    </Stack>
  );
};
