import React from "react";
import "./styles/site.scss";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { MsalTokenWrapper } from "./components/MsalTokenWrapper";

export const App: React.FunctionComponent = () => {
  return (
    <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
      <MsalTokenWrapper></MsalTokenWrapper>
    </MsalAuthenticationTemplate>
  );
};
