import { IComboBoxOption } from "@fluentui/react";
import { AccessTokens, axiosInstance } from "./BaseService";

export type FeatureDto = {
    name: string;
    state: boolean;
    defaultState: boolean;
    subscriptionIds: string[];
    participantIds: string[];
};

export type FeatureListDto = {
    features: FeatureDto[];
    subscriptions: IComboBoxOption[];
    participants: IComboBoxOption[];
};

export const getFeatureList = (tokens: AccessTokens) => {
  return axiosInstance
    .get("api/features", {
      headers: {
        Authorization: "Bearer " + tokens.application,
        apim: "Bearer " + tokens.apim,
      },
    })
    .then((resp) => {
      return resp.data as FeatureListDto;
    })
    .catch((error) => {
      console.log(error);
      return undefined;
    });
};

export const createFeature = (
  tokens: AccessTokens,
  feature: FeatureDto
) => {
  return axiosInstance
    .post(`api/features`, feature, {
      headers: {
        Authorization: "Bearer " + tokens.application,
        apim: "Bearer " + tokens.apim,
      },
    })
    .then((resp) => {
      return resp.data;
    })
    .catch((error) => {
      console.log(error);
      return undefined;
    });
};

export const updateFeature = (
  tokens: AccessTokens,
  feature: FeatureDto
) => {
  return axiosInstance
    .put(`api/features`, feature, {
      headers: {
        Authorization: "Bearer " + tokens.application,
        apim: "Bearer " + tokens.apim,
      },
    })
    .then((resp) => {
      return resp.data;
    })
    .catch((error) => {
      console.log(error);
      return undefined;
    });
};


export const deleteFeatures = (tokens: AccessTokens, names: string[]) => {
  return axiosInstance
    .delete("api/features", {
      headers: {
        Authorization: "Bearer " + tokens.application,
        apim: "Bearer " + tokens.apim,
      },
      data: names,
    })
    .then((resp) => {
      return resp.data;
    })
    .catch((error) => {
      console.log(error);
    });
};