import { ComboBox, DefaultButton, PrimaryButton, Stack, TextField } from '@fluentui/react';
import React, { useMemo, useState, useCallback, useEffect, useImperativeHandle } from 'react';

import { ParticipantSubscriptionDto } from '../services/API/ParticipantService';
import { SubscriptionFullDto } from '../services/API/SubscriptionService';
import { generateClientCertificate } from '../services/CertificateService';

export type SubscriptionEditBoxRefProps = {
  setValues: (subscription: ParticipantSubscriptionDto) => void;
};

type SubscriptionEditBoxProps = {
  availableSubscriptions: SubscriptionFullDto[];
  subscriptionId?: string;
  participantUid?: string;

  onUpdate: (thumbprint: string, expiredDate: string | undefined, rootThumbprint: string, issuer: string, subject: string) => void;
  onCreate: (selectedSubscription: string, thumbprint: string, expiredDate: string | undefined, rootThumbprint: string, issuer: string, subject: string) => void;
  onCancel: () => void;
};

export const SubscriptionEditBox = React.forwardRef<SubscriptionEditBoxRefProps, SubscriptionEditBoxProps>((props, ref) => {
  const { availableSubscriptions = [], subscriptionId, participantUid, onUpdate, onCreate, onCancel } = props;

  useImperativeHandle(ref, () => ({
    setValues: ({
      certificateThumbprint,
      certificateExpiredAfter,
      certificateRootThumbprint = '',
      certificateIssuer = '',
      certificateSubject = '',
    }: ParticipantSubscriptionDto) => {
      setThumbprint(certificateThumbprint);
      setExpiredDate(parseDateString(certificateExpiredAfter || ''));
      setRootThumbprint(certificateRootThumbprint);
      setIssuer(certificateIssuer);
      setSubject(certificateSubject);
      setSelectedSubscription(undefined);
    },
  }));

  const [thumbprint, setThumbprint] = useState('');
  const [rootThumbprint, setRootThumbprint] = useState('');
  const [issuer, setIssuer] = useState('');
  const [subject, setSubject] = useState('');
  const [certificate, setCertificate] = useState('');
  const [expiredDate, setExpiredDate] = useState<string>();
  const [selectedSubscription, setSelectedSubscription] = useState<string>();

  const subscriptionItems = useMemo(
    () =>
      availableSubscriptions.map((s) => ({
        key: s.name,
        text: `${s.scopeIdentifier} - ${s.name} - ${s.displayName} - ${s.ownerIdentifier} - ${s.ownerEmail} - ${s.ownerName}`,
      })),
    [availableSubscriptions],
  );

  const onGenerateCertificateClick = useCallback(() => {
    const cert = generateClientCertificate(subscriptionId);
    setCertificate(cert.base64content);
    setThumbprint(cert.thumbprint);
    setExpiredDate(parseDate(cert.expiredAfter));
  }, [subscriptionId]);

  const onDownloadCertificate = useCallback(() => {
    const element = document.createElement('a');
    element.href = 'data:application/x-pkcs12;base64,' + certificate;
    element.download = `${participantUid || subscriptionId}-client_certificate.p12`;
    document.body.appendChild(element);
    element.click();
  }, [certificate]);

  const cleanUpState = useCallback(() => {
    setThumbprint('');
    setExpiredDate('');
    setIssuer('');
    setRootThumbprint('');
    setSubject('');
    setSelectedSubscription('');
    setCertificate('');
  }, []);

  const onCancelClick = useCallback(() => {
    onCancel();
    cleanUpState();
  }, [onCancel]);

  const onUpdateClick = useCallback(() => {
    onUpdate(thumbprint, expiredDate, rootThumbprint, issuer, subject);
    cleanUpState();
  }, [thumbprint, expiredDate, rootThumbprint, issuer, subject, onUpdate, cleanUpState]);

  const onCreateClick = useCallback(() => {
    onCreate(selectedSubscription || '', thumbprint, expiredDate, rootThumbprint, issuer, subject);
  }, [thumbprint, expiredDate, rootThumbprint, issuer, subject, selectedSubscription, onCreate]);

  useEffect(() => {
    setSelectedSubscription(subscriptionId);
  }, [subscriptionId]);

  return (
    <>
      <Stack horizontal tokens={{ childrenGap: 10 }} verticalAlign="center" style={{ marginBottom: '8px' }}>
        <DefaultButton onClick={() => onGenerateCertificateClick()} className="labelButton">
          Generate new certificate
        </DefaultButton>
        {certificate && (
          <DefaultButton onClick={onDownloadCertificate} iconProps={{ iconName: 'Download' }} className="labelButton">
            {participantUid || subscriptionId}-client_certificate.p12
          </DefaultButton>
        )}
      </Stack>
      <Stack tokens={{ childrenGap: 10 }} horizontal wrap>
        <Stack.Item>
          <ComboBox
            selectedKey={selectedSubscription}
            disabled={!!subscriptionId}
            label="Subscription Id"
            placeholder="Subscription Id"
            options={subscriptionItems}
            onChange={(ev, opt) => setSelectedSubscription(opt?.key as string)}
            style={{ width: '300px' }}
            multiSelect={false}
          />
        </Stack.Item>
        <Stack.Item>
          <TextField
            label={'Certificate Thumbprint'}
            placeholder={'Certificate Thumbprint'}
            value={thumbprint}
            style={{ width: '300px' }}
            onChange={(ev, text) => {
                setThumbprint((text || '').toUpperCase());
            }}
          />
        </Stack.Item>
        <Stack.Item>
          <TextField
            label={'Certificate Expired Date'}
            placeholder={'Certificate Expired Date'}
            type="date"
            value={expiredDate}
            style={{ width: '300px' }}
            onChange={(ev, text) => {
              setExpiredDate(parseDateString(text || ''));
            }}
          />
        </Stack.Item>
        <Stack.Item align="end">
          <PrimaryButton onClick={subscriptionId ? onUpdateClick : onCreateClick}>{subscriptionId ? 'Update' : 'Create'}</PrimaryButton>
        </Stack.Item>
        <Stack.Item>
          <TextField
            label="Root Certificate Thumbprint"
            placeholder={'Root Certificate Thumbprint'}
            value={rootThumbprint}
            onChange={(ev, text) => setRootThumbprint((text || '').toUpperCase())}
            style={{ width: '300px' }}
          />
        </Stack.Item>
        <Stack.Item>
          <TextField
            label="Root Certificate Issuer"
            placeholder={'Root Certificate Issuer'}
            value={issuer}
            onChange={(ev, text) => setIssuer(text || '')}
            style={{ width: '300px' }}
          />
        </Stack.Item>
        <Stack.Item>
          <TextField
            label="Root Certificate Subject"
            placeholder={'Root Certificate Subject'}
            value={subject}
            onChange={(ev, text) => setSubject(text || '')}
            style={{ width: '300px' }}
          />
        </Stack.Item>
        {participantUid && subscriptionId && (
          <Stack.Item align="end">
            <DefaultButton onClick={onCancelClick}>Cancel</DefaultButton>
          </Stack.Item>
        )}
      </Stack>
    </>
  );
});

const getDateFromDatetime = (datetime: string) => datetime.split('T')[0];
const parseDate = (date: Date) => getDateFromDatetime(date.toISOString());
const parseDateString = (date: string) => (date ? parseDate(new Date(getDateFromDatetime(date))) : '');
