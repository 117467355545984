import axios from "axios";
import { Configuration, LogLevel } from "@azure/msal-browser";

export const axiosInstance = axios.create({
    baseURL: window.location.origin
});

export type AccessTokens = {
    application?: string;
    apim?: string;
};

export const AdminApiScope = `api://${process.env.REACT_APP_AuthenticationConfiguration_clientId || ''}/full_admin_access`;
export const ApiManagementScope = "https://management.azure.com/user_impersonation";

export const AuthenticationConfiguration: Configuration = {
    auth: {
        clientId: process.env.REACT_APP_AuthenticationConfiguration_clientId || '',
        authority: `https://login.microsoftonline.com/${process.env.REACT_APP_AuthenticationConfiguration_tenantId}`,
        redirectUri: process.env.REACT_APP_AuthenticationConfiguration_redirectUri,
    },
    cache: {
        cacheLocation: "sessionStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
        loggerOptions: {
            loggerCallback: (level: any, message: any, containsPii: any) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                }
            },
        },
    },
};
