import { Stack, Text } from "@fluentui/react";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { AccessTokens } from "../../../services/API/BaseService";
import { RequestCountDto, getRequestCount } from "../../../services/API/StatisticsService";

import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from "recharts";
import moment from "moment";

type CardProps = {
  accessTokens: AccessTokens;
};

export const RequestCountsCard: React.FC<CardProps> = ({ accessTokens }: CardProps) => {
  const [requestCounts, setRequestCounts] = useState<RequestCountDto[]>();

  useEffect(() => {
    getRequestCount(accessTokens).then((d) => {
      let stats = d.sort((o1, o2) => {
        if (o1.timestamp < o2.timestamp) return -1;
        else if (o1.timestamp > o2.timestamp) return 1;
        else return 0;
      });

      stats = d.map((d: any) => {
        return { timestamp: moment(d.timestamp).valueOf(), totalCount: d.totalCount };
      }) as any;

      setRequestCounts(stats as any);
    });
  }, [setRequestCounts]);

  return (
    <Stack className="dashboardCardWrapper responseTimeTrendCard">
      <Text variant="large">Requests Count</Text>
      <Stack className="dashboardCardListWrapper">
        <ResponsiveContainer width="100%" height={300}>
          <LineChart width={1200} height={300} data={requestCounts} margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
            {requestCounts && requestCounts.length > 0 && (
              <XAxis
                domain={[requestCounts[0].timestamp, requestCounts[requestCounts.length - 1].timestamp]}
                dataKey="timestamp"
                tickFormatter={dateFormatter}
                scale="time"
                type="number"
                ticks={ticks as any}
                fontSize={12}
              />
            )}
            <YAxis fontSize={12} />
            <CartesianGrid strokeDasharray="3 3" />
            <Line type="monotone" dataKey="totalCount" stroke="#3e78d1" strokeWidth="3" isAnimationActive={false} />
            <Tooltip content={<CustomTooltip />} />
          </LineChart>
        </ResponsiveContainer>
      </Stack>
    </Stack>
  );
};

const ticks = Array(24)
  .fill(1)
  .map((x, y) => {
    let date = new Date();
    date.setHours(date.getHours() - y);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
    return date;
  });

const dateFormatter = (item: any) => moment(item).format("H:mm");

const CustomTooltip = ({ active, payload, label }: any) => {
  if (active && payload && payload.length) {
    return (
      <Stack className="chartTooltipWrapper">
        <Text>{moment(label).format("H:mm")}</Text>
        <Text>{parseInt(payload[0].value)} requests</Text>
      </Stack>
    );
  }

  return null;
};
