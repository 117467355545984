import { ComboBox, DefaultButton, IComboBoxOption, Label, Panel, PanelType, PrimaryButton, Separator, Stack, TextField, Toggle } from '@fluentui/react';
import React, { useEffect, useCallback, useState } from 'react';
import { SubscriptionFullDto } from '../../services/API/SubscriptionService';
import { ExtendedParticipantDto, ParticipantSubscriptionDto } from '../../services/API/ParticipantService';
//import { SubscriptionsList } from './SubscriptionsList';
import { DeleteDialog } from './DeleteDialog';
import keypair from 'keypair';
import { SubscriptionsEditor } from '../../components';

type ParticipantEditPanelProps = {
  participant: ExtendedParticipantDto | undefined;
  onSave: (participant: ExtendedParticipantDto) => void;
  onDelete: (UID: string) => void;
  onDismiss: () => void;
  showDelete?: boolean;
  isOpen: boolean;
  allSubscriptions: SubscriptionFullDto[];
};

export const defaultSystemOptions: IComboBoxOption[] = [
  { key: 'EASX', text: 'EASX' },
  { key: 'BVGExchange', text: 'BVGExchange' },
];
const defaultServiceOptions: IComboBoxOption[] = [{ key: 'EAS', text: 'EAS' }];
const defaultTypeOptions: IComboBoxOption[] = [
  {
    key: 'http://exchange.aeis.ch/xsd/FZL',
    text: 'http://exchange.aeis.ch/xsd/FZL',
  },
];
const defaultActionOptions: IComboBoxOption[] = [
  { key: 'send', text: 'send' },
  { key: 'receive', text: 'receive' },
];

export const ParticipantEditPanel: React.FC<ParticipantEditPanelProps> = ({ participant, onSave, onDelete, onDismiss, showDelete, isOpen, allSubscriptions }) => {
  const [uid, setUid] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [publicKey, setPublicKey] = useState<string>('');
  const [publicKeyXml, setPublicKeyXml] = useState<string>('');
  const [altPublicKey, setAltPublicKey] = useState<string>('');
  const [altPublicKeyXml, setAltPublicKeyXml] = useState<string>('');
  const [supportedVersions, setSupportedVersions] = useState<string>('');
  const [system, setSystem] = useState<string>('');
  const [service, setService] = useState<string>('');
  const [type, setType] = useState<string>('');
  const [actions, setActions] = useState<string[]>([]);
  const [place, setPlace] = useState<string>('');
  const [street, setStreet] = useState<string>('');
  const [zipCode, setZipCode] = useState<string>('');

  const [subscriptions, setSubscriptions] = useState<ParticipantSubscriptionDto[]>([]);

  const [systemOptions, setSystemOptions] = useState(defaultSystemOptions);
  const [serviceOptions, setServiceOptions] = useState(defaultServiceOptions);
  const [typeOptions, setTypeOptions] = useState(defaultTypeOptions);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [privateKey, setPrivateKey] = useState('');

  useEffect(() => {
    if (participant) {
      setUid(participant.uid);
      setName(participant.name);
      setPublicKey(participant.publicKey);
      setPublicKeyXml(participant.publicKeyXml);
      setAltPublicKey(participant.altPublicKey);
      setAltPublicKeyXml(participant.altPublicKeyXml);
      setSupportedVersions(participant.supportedVersions);
      setSystem(participant.system);
      setService(participant.service);
      setType(participant.type);
      setActions(participant.actions ?? []);
      setPlace(participant.place);
      setStreet(participant.street);
      setZipCode(participant.zipCode);
      setSubscriptions(participant.subscriptions ?? []);
      setPrivateKey('');
    } else {
      setUid('');
      setName('');
      setPublicKey('');
      setPublicKeyXml('--will be calculated automatically--');
      setAltPublicKey('');
      setAltPublicKeyXml('--will be calculated automatically--');
      setSupportedVersions('1.5');
      setSystem(defaultSystemOptions[0].text);
      setService(defaultServiceOptions[0].text);
      setType(defaultTypeOptions[0].text);
      setActions([defaultActionOptions[0].text, defaultActionOptions[1].text]);
      setPlace('');
      setStreet('');
      setZipCode('');
      setSubscriptions([]);
      setPrivateKey('');
    }
  }, [participant]);

  const onSaveClick = () => {
    onSave({
      uid: uid,
      name: name,
      publicKey: publicKey,
      publicKeyXml: publicKeyXml,
      altPublicKey: altPublicKey,
      altPublicKeyXml: altPublicKeyXml,
      supportedVersions: supportedVersions,
      system: system,
      service: service,
      type: type,
      actions: actions,
      place: place,
      street: street,
      zipCode: zipCode,
      subscriptions: subscriptions.map((x) => ({ ...x, certificateExpiredAfter: x.certificateExpiredAfter || undefined })),
    });
  };

  const onGenerateKeys = () => {
    const keys = keypair({ bits: 2048 });
    setAltPublicKey(publicKey);
    setAltPublicKeyXml(publicKeyXml);
    setPublicKey(keys.public);
    setPrivateKey(keys.private);
  };

  const onDownloadPrivateKey = () => {
    const element = document.createElement('a');
    element.href = URL.createObjectURL(new Blob([privateKey], { type: 'text/plain' }));
    element.download = `${uid}-${new Date().getFullYear()}/${new Date().getMonth() + 1}/${new Date().getDate()}-private_key.pem`;
    document.body.appendChild(element);
    element.click();
  };

  const onUpdateSubscription = useCallback(
    (subs: ParticipantSubscriptionDto[]) => {
      setSubscriptions((s) =>
        s.map((ps) => {
          const updatedSub = subs.find((us) => us.id === ps.id);

          if (updatedSub) return updatedSub;

          return ps;
        }),
      );
    },
    [setSubscriptions],
  );

  const onDeleteSubscription = useCallback(
    (sub: ParticipantSubscriptionDto) => {
      setSubscriptions((s) => s.filter((cs) => cs.id !== sub.id));
    },
    [setSubscriptions],
  );

  const onCreateSubscription = useCallback(
    (sub: ParticipantSubscriptionDto) => {
      setSubscriptions((s) => [...s, sub]);
    },
    [setSubscriptions],
  );

  return (
    <Panel
      type={PanelType.large}
      headerText={`Edit Participant`}
      onDismiss={onDismiss}
      isOpen={isOpen}
      closeButtonAriaLabel="Close"
      onRenderFooterContent={() => (
        <>
          <Separator />
          <Stack horizontal tokens={{ childrenGap: 10 }}>
            <PrimaryButton onClick={onSaveClick}>Save</PrimaryButton>
            {showDelete && (
              <PrimaryButton
                onClick={() => {
                  setShowDeleteDialog(true);
                }}
                styles={{ root: { backgroundColor: 'red' } }}
              >
                Delete Participant
              </PrimaryButton>
            )}
            <DefaultButton onClick={onDismiss}>Cancel</DefaultButton>
          </Stack>
        </>
      )}
    >
      {participant && (
        <>
          <TextField label={'UID'} value={uid} onChange={(ev, text) => setUid(text || '')} />
          <TextField label={'Name'} value={name} onChange={(ev, text) => setName(text || '')} />
          <TextField label={'Supported Versions'} value={supportedVersions} onChange={(ev, text) => setSupportedVersions(text || '')} />
          <ComboBox
            label="System"
            allowFreeform
            autoComplete={'on'}
            options={systemOptions}
            selectedKey={system}
            onChange={(ev, option, index, value) => {
              if (!option && value) setSystemOptions((prevOptions) => [...prevOptions, { key: value, text: value }]);
              setSystem(value ?? (option && (option.key as string)) ?? '');
            }}
          />
          <ComboBox
            label="Service"
            allowFreeform
            autoComplete={'on'}
            options={serviceOptions}
            selectedKey={service}
            onChange={(ev, option, index, value) => {
              if (!option && value) setServiceOptions((prevOptions) => [...prevOptions, { key: value, text: value }]);
              setService(value ?? (option && (option.key as string)) ?? '');
            }}
          />
          <ComboBox
            label="Type"
            allowFreeform
            autoComplete={'on'}
            options={typeOptions}
            selectedKey={type}
            onChange={(ev, option, index, value) => {
              if (!option && value) setTypeOptions((prevOptions) => [...prevOptions, { key: value, text: value }]);
              setType(value ?? (option && (option.key as string)) ?? '');
            }}
          />
          <ComboBox
            label="Actions"
            allowFreeform={false}
            options={defaultActionOptions}
            multiSelect
            selectedKey={actions}
            onChange={(ev, option, index, value) => {
              option && setActions(option?.selected ? [...actions, option!.key as string] : actions.filter((k) => k !== option!.key));
            }}
          />
          <TextField label={'Place'} value={place} onChange={(ev, text) => setPlace(text || '')} />
          <TextField label={'Street'} value={street} onChange={(ev, text) => setStreet(text || '')} />
          <TextField label={'Zip Code'} value={zipCode} onChange={(ev, text) => setZipCode(text || '')} />

          <Stack horizontal tokens={{ childrenGap: 10 }} verticalAlign="center">
            <Label>Public Key</Label>

            <DefaultButton onClick={onGenerateKeys} className="labelButton">
              Generate new keys
            </DefaultButton>
            {privateKey && (
              <DefaultButton onClick={onDownloadPrivateKey} iconProps={{ iconName: 'Download' }} className="labelButton">
                {uid}-{new Date().getFullYear()}/{new Date().getMonth() + 1}/{new Date().getDate()}-private_key.pem
              </DefaultButton>
            )}
          </Stack>

          <TextField value={publicKey} rows={10} multiline onChange={(ev, text) => setPublicKey(text || '')} />
          <TextField label={'Public Key Xml'} description={'Xml Key will be (re)generated automatically on the server side'} value={publicKeyXml} readOnly />
          <Stack horizontal tokens={{ childrenGap: 10 }} verticalAlign="center">
            <Label>Alternate Public Key</Label>
          </Stack>
          <TextField value={altPublicKey} rows={10} multiline onChange={(ev, text) => setAltPublicKey(text || '')} />
          <TextField label={'Alternate Public Key Xml'} description={'Xml Key will be (re)generated automatically on the server side'} value={altPublicKeyXml} readOnly />
          <br />
          <Separator />

          <SubscriptionsEditor
            subscriptions={subscriptions}
            availableSubscriptions={allSubscriptions}
            onUpdateSubsciptions={onUpdateSubscription}
            onDeleteSubscription={onDeleteSubscription}
            onCreateSubscription={onCreateSubscription}
            displaySubscriptionId
            participantUid={uid}
            displayActions
          />

          {/* <SubscriptionsList
            subscriptions={subscriptions}
            setSubscriptions={setSubscriptions}
            allSubscriptions={allSubscriptions}
            uid={uid}
          ></SubscriptionsList> */}
          <DeleteDialog isOpen={showDeleteDialog} onDelete={() => onDelete(participant.uid)} onDismiss={() => setShowDeleteDialog(false)}></DeleteDialog>
        </>
      )}
    </Panel>
  );
};
