import { DetailsListLayoutMode, IColumn, SelectionMode, ShimmeredDetailsList, Stack, Text } from "@fluentui/react";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { AccessTokens } from "../../../services/API/BaseService";
import { getLastExceptions, LastExceptionDto } from "../../../services/API/StatisticsService";
import { DetailsPanel } from "./DetailsPanel";

const columns: IColumn[] = [
  {
    key: "timestamp",
    name: "Timestamp",
    fieldName: "timestamp",
    minWidth: 120,
    maxWidth: 120,
    isResizable: true,
    onRender: (item: LastExceptionDto) => !!item.timestamp && new Date(item.timestamp).toLocaleString(),
  },
  {
    key: "operation_Name",
    name: "Operation Name",
    fieldName: "operation_Name",
    minWidth: 150,
    maxWidth: 150,
    isResizable: true,
  },
  {
    key: "cloud_RoleName",
    name: "Cloud Role Name",
    fieldName: "cloud_RoleName",
    minWidth: 120,
    maxWidth: 120,
    isResizable: true,
  },
  {
    key: "type",
    name: "Type",
    fieldName: "type",
    minWidth: 300,
    maxWidth: 300,
    isResizable: true,
  },
  {
    key: "method",
    name: "Method",
    fieldName: "method",
    minWidth: 300,
    maxWidth: 300,
    isResizable: true,
  },
  {
    key: "outerMessage",
    name: "Outer Message",
    fieldName: "outerMessage",
    minWidth: 300,
    maxWidth: 300,
    isResizable: true,
  },
];

type CardProps = {
  accessTokens: AccessTokens;
};

export const LastExceptionsCard: React.FC<CardProps> = ({ accessTokens }: CardProps) => {
  const [lastExceptions, setLastExceptions] = useState<LastExceptionDto[]>();
  const [detailsItem, setDetailsItem] = useState<LastExceptionDto>();

  useEffect(() => {
    getLastExceptions(accessTokens).then(setLastExceptions);
  }, [setLastExceptions]);

  return (
    <Stack className="dashboardCardWrapper lastExceptionsCard">
      <Text variant="large">Last Exceptions</Text>
      <Stack className="dashboardCardListWrapper">
        <ShimmeredDetailsList
          items={lastExceptions || []}
          columns={columns}
          layoutMode={DetailsListLayoutMode.justified}
          selectionMode={SelectionMode.none}
          enableShimmer={!lastExceptions}
          shimmerLines={10}
          onItemInvoked={setDetailsItem}
        />
      </Stack>
      <DetailsPanel isOpen={!!detailsItem} onDismiss={() => setDetailsItem(undefined)} item={detailsItem} />
    </Stack>
  );
};
