import React, { useEffect, useState } from "react";
import { DetailsListLayoutMode, IColumn, SelectionMode } from "@fluentui/react/lib/DetailsList";
import { ShimmeredDetailsList } from "@fluentui/react/lib/ShimmeredDetailsList";
import { AccessTokens } from "../../services/API/BaseService";
import { getSubscriptions, SubscriptionFullDto, SubscriptionDtoState, ProductFullDto, getProducts } from "../../services/API/SubscriptionService";
import { CommandBar, ICommandBarItemProps, Icon, Stack } from "@fluentui/react";
import { Text } from "@fluentui/react/lib/Text";
import { DetailsPanel } from "./DetailsPanel";
import { useCallback } from "react";
import { useMemo } from "react";
import { useHistory } from "react-router-dom";

const subscriptionListColumns: IColumn[] = [
  {
    key: "icon",
    name: "",
    fieldName: "icon",
    minWidth: 20,
    maxWidth: 20,
    isResizable: false,
    isIconOnly: true,
    iconName: "Link",

    onRender: (item: SubscriptionFullDto) =>
      item.state === SubscriptionDtoState.Active ? (
        <Icon iconName="PageLink" className="documentListIcon" />
      ) : item.state === SubscriptionDtoState.Suspended ||
        item.state === SubscriptionDtoState.Expired ||
        item.state === SubscriptionDtoState.Rejected ||
        item.state === SubscriptionDtoState.Cancelled ? (
        <Icon iconName="PageLink" className="documentListIconError" />
      ) : (
        <Icon iconName="PageLink" className="documentListIconSuccess" />
      ),
  },
  {
    key: "scopeIdentifier",
    name: "Scope Identifier",
    fieldName: "scopeIdentifier",
    minWidth: 100,
    maxWidth: 200,
    isResizable: true,
  },
  {
    key: "name",
    name: "Name",
    fieldName: "name",
    minWidth: 100,
    maxWidth: 200,
    isResizable: true,
  },
  {
    key: "displayName",
    name: "Display Name",
    fieldName: "displayName",
    minWidth: 100,
    maxWidth: 200,
    isResizable: true,
  },
  {
    key: "ownerName",
    name: "Owner Name",
    fieldName: "ownerName",
    minWidth: 100,
    maxWidth: 200,
    isResizable: true,
  },
  {
    key: "ownerEmail",
    name: "Owner Email",
    fieldName: "ownerEmail",
    minWidth: 100,
    maxWidth: 200,
    isResizable: true,
  },
  {
    key: "createdDate",
    name: "Created Date",
    fieldName: "createdDate",
    minWidth: 100,
    maxWidth: 200,
    isResizable: true,
    onRender: (item: SubscriptionFullDto) => !!item.createdDate && new Date(item.createdDate).toLocaleString(),
  },
  {
    key: "expirationDate",
    name: "Expiration Date",
    fieldName: "expirationDate",
    minWidth: 100,
    maxWidth: 200,
    isResizable: true,
    onRender: (item: SubscriptionFullDto) => !!item.expirationDate && new Date(item.expirationDate).toLocaleString(),
  },
  {
    key: "startDate",
    name: "Start Date",
    fieldName: "startDate",
    minWidth: 100,
    maxWidth: 200,
    isResizable: true,
    onRender: (item: SubscriptionFullDto) => !!item.startDate && new Date(item.startDate).toLocaleString(),
  },
  {
    key: "endDate",
    name: "End Date",
    fieldName: "endDate",
    minWidth: 100,
    maxWidth: 200,
    isResizable: true,
    onRender: (item: SubscriptionFullDto) => !!item.endDate && new Date(item.endDate).toLocaleString(),
  },
  {
    key: "allowTracing",
    name: "Allow Tracing",
    fieldName: "allowTracing",
    minWidth: 100,
    maxWidth: 200,
    isResizable: true,
  },
  {
    key: "state",
    name: "State",
    fieldName: "state",
    minWidth: 100,
    maxWidth: 200,
    isResizable: true,
    onRender: (item: SubscriptionFullDto) => !!item.state && SubscriptionDtoState[item.state],
  },
];

type SubscriptionsPageProps = {
  accessTokens: AccessTokens;
};

export const SubscriptionsPage: React.FC<SubscriptionsPageProps> = ({ accessTokens }: SubscriptionsPageProps) => {
  const [subscriptions, setSubscriptions] = useState<SubscriptionFullDto[]>();

  const [detailsItem, setDetailsItem] = useState<SubscriptionFullDto>();

  const history = useHistory();

  const refreshSubscriptions = useCallback(() => {
    setSubscriptions(undefined);
    getSubscriptions(accessTokens).then(setSubscriptions);
  }, [setSubscriptions, accessTokens]);

  const commandBarItems: ICommandBarItemProps[] = useMemo(
    () => [
      {
        key: "reload",
        text: "Reload",
        iconProps: { iconName: "Refresh" },
        onClick: () => refreshSubscriptions(),
      },
    ],
    [refreshSubscriptions]
  );

  const navigateToDetailsPage = useCallback((subscription: SubscriptionFullDto) => {
    history.push(`subscriptions/${subscription.name}`);
  },[history])

  useEffect(() => {
    refreshSubscriptions();
  }, []);


  return (
    <Stack className="adminPage">
      <div className="pageHeader">
        <Text variant="xLarge">Subscriptions</Text>
      </div>

      <CommandBar items={commandBarItems} className="pageCommandBar" />

      <ShimmeredDetailsList
        items={subscriptions || []}
        columns={subscriptionListColumns}
        layoutMode={DetailsListLayoutMode.justified}
        selectionMode={SelectionMode.none}
        onItemInvoked={navigateToDetailsPage}
        enableShimmer={!subscriptions}
        shimmerLines={10}
      />
    </Stack>
  );
};
