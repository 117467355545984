import React, { useCallback, useEffect, useState } from "react";
import { Dropdown, Panel, PanelType, ProgressIndicator, Separator, Stack, Toggle } from "@fluentui/react";
import { AccessTokens } from "../../services/API/BaseService";
import { getLogs } from "../../services/API/LogService";
import { getParticipant } from "../../services/API/ParticipantService";
import { ReactSVG } from "react-svg";

enum DocumentStatus {
  Failed = -1,
  Unknown,
  Add,
  Send,
  Read,
  ReceiptSend,
  ReceiptRead,
  ReceiptDelete,
}

const statusActionNames = {
  "out-documents created": DocumentStatus.Add,
  "out-documents sent": DocumentStatus.Send,
  "in-document retrieved": DocumentStatus.Read,
  "in-documents deleted (not acknowledged, error receipt sent)": DocumentStatus.ReceiptSend,
  "in-documents deleted (acknowledged, receipt sent)": DocumentStatus.ReceiptSend,
  "receipts retrieved": DocumentStatus.ReceiptRead,
  "receipt removed": DocumentStatus.ReceiptDelete,
};

export const PreviewPanel: React.FC<{
  isOpen: boolean;
  documentId: string;
  accessTokens: AccessTokens;
  onDismiss: () => void;
}> = ({ isOpen, onDismiss, documentId, accessTokens }) => {
  const [status, setStatus] = useState<DocumentStatus>(DocumentStatus.Unknown);
  const [senderName, setSenderName] = useState<string>("");
  const [receiverName, setReceiverName] = useState<string>("");
  const [senderUid, setSenderUid] = useState<string>("");
  const [receiverUid, setReceiverUid] = useState<string>("");

  const [isRealTime, setIsRealTime] = useState(false);
  const [refreshInterval, setRefreshInterval] = useState(10);

  useEffect(() => {
    let interval: any;
    if (isRealTime) {
      interval = setInterval(() => {
        refreshData();
      }, refreshInterval * 1000);
    } else {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [isRealTime, refreshInterval]);

  const refreshData = () => {
    if (documentId) {
      getLogs(accessTokens, { documentId: documentId }).then((logs) => {
        // Determine current status
        let lastStatus = DocumentStatus.Failed;

        // Check if the document was uploaded
        let add = logs.find((l) => (statusActionNames as any)[l.actionName] === DocumentStatus.Add);
        if (add) {
          lastStatus = DocumentStatus.Add;
          !senderUid && setSenderUid(add.senderId);
          !receiverUid && setReceiverUid(add.receiverId);
          !senderName && getParticipant(accessTokens, add.senderId).then((p) => setSenderName(p?.name ?? ""));
          !receiverName && getParticipant(accessTokens, add.receiverId).then((p) => setReceiverName(p?.name ?? ""));
        }

        // Check if the document was sent
        if (lastStatus === DocumentStatus.Add) {
          let send = logs.find((l) => (statusActionNames as any)[l.actionName] === DocumentStatus.Send);
          if (send) lastStatus = DocumentStatus.Send;
        }

        // Check if the document was read
        if (lastStatus === DocumentStatus.Send) {
          let read = logs.find((l) => (statusActionNames as any)[l.actionName] === DocumentStatus.Read);
          if (read) lastStatus = DocumentStatus.Read;
        }

        // Check if the receipt was sent
        if (lastStatus === DocumentStatus.Read) {
          let receiptSend = logs.find((l) => (statusActionNames as any)[l.actionName] === DocumentStatus.ReceiptSend);
          if (receiptSend) lastStatus = DocumentStatus.ReceiptSend;
        }

        if (lastStatus !== DocumentStatus.ReceiptSend) {
          // A receipt was not sent
          setStatus(lastStatus);
        } else {
          // Check if receipts were read
          let receiptSend = logs.find((l) => (statusActionNames as any)[l.actionName] === DocumentStatus.ReceiptSend);
          getLogs(accessTokens, { sender: receiptSend?.senderId, fromDate: receiptSend?.createdOn, actionName: "receipts retrieved" }).then(
            (receiptReadLogs) => {
              if (receiptReadLogs?.length > 0) lastStatus = DocumentStatus.ReceiptRead;

              // Participant did not read theirs receipts (or we don't have access to get logs for that UID)
              //if (lastStatus !== DocumentStatus.ReceiptRead) {
              //  setStatus(lastStatus);
              //} else {
              // Check if a participant deleted the receipt
              let receiptDelete = logs.find((l) => (statusActionNames as any)[l.actionName] === DocumentStatus.ReceiptDelete);
              if (receiptDelete) lastStatus = DocumentStatus.ReceiptDelete;

              setStatus(lastStatus);
              //}
            }
          );
        }
      });
    }
  };

  useEffect(() => {
    refreshData();
  }, [documentId]);

  const onBeforeDismiss = useCallback(() => {
    onDismiss();
    setStatus(DocumentStatus.Unknown);
  }, [onDismiss]);

  const afterSvgLoad = useCallback(
    (svg?: SVGSVGElement) => {

      const successColor = "#80aa4d";
      const defaultColor = "#a6a6a6";

      const getByXpath = (xpath: string) =>
        (document.evaluate(xpath, svg as any, null, XPathResult.FIRST_ORDERED_NODE_TYPE, null).singleNodeValue as any) ?? {};

      const setHtmlByText = (text: string, html: string) => {
        const textDiv = getByXpath(`//div[text()='${text}']`);
        if (textDiv) {
          textDiv.innerHTML = html;
          textDiv.className = "previewPanelHeaderText";
        }
        getByXpath(`//text[text()='${text}']`).innerHTML = html;
      };

      const setColorByText = (text: string, color: string) => {
        const textDiv = getByXpath(`//div[text()='${text}']`);
        if (textDiv && textDiv.style) {
          const closest = textDiv.closest("g");
          closest && closest.previousSibling && closest.previousSibling.setAttribute("fill", color);
        }
      };

      setHtmlByText("SENDER_UID", senderUid);
      setHtmlByText("SENDER_NAME", senderName);
      setHtmlByText("RECEIVER_UID", receiverUid);
      setHtmlByText("RECEIVER_NAME", receiverName);

      setColorByText("Add", status >= DocumentStatus.Add ? successColor : defaultColor);
      setColorByText("Send", status >= DocumentStatus.Send ? successColor : defaultColor);
      setColorByText("Read", status >= DocumentStatus.Read ? successColor : defaultColor);
      setColorByText("Receipt Send", status >= DocumentStatus.ReceiptSend ? successColor : defaultColor);
      setColorByText("Receipt Read", status >= DocumentStatus.ReceiptRead ? successColor : defaultColor);
      setColorByText("Receipt Delete", status >= DocumentStatus.ReceiptDelete ? successColor : defaultColor);
    },
    [status, senderUid, senderName, receiverUid, receiverName]
  );

  return (
    <Panel
      headerText={`Log Preview`}
      isOpen={isOpen}
      onDismiss={onBeforeDismiss}
      closeButtonAriaLabel="Close"
      type={PanelType.largeFixed}
      isBlocking
      isLightDismiss
    >
      <Stack>
        {status === DocumentStatus.Failed ? (
          <>There is not enough data in logs to preview state of this document.</>
        ) : status !== DocumentStatus.Unknown && senderName && senderUid && receiverName && receiverUid ? (
          <>
            <Separator />

            <ReactSVG src="/assets/log-preview-diagram.svg" afterInjection={afterSvgLoad} />
            <Separator />

            <Stack horizontal tokens={{ childrenGap: 15 }}>
              <Toggle
                inlineLabel
                label="Live Refresh"
                onText="On"
                offText="Off"
                onChange={(_, val) => {
                  setIsRealTime(val === true);
                }}
              />

              {isRealTime && (
                <Dropdown
                  selectedKey={refreshInterval}
                  onChange={(_, option) => setRefreshInterval(option?.key as number)}
                  placeholder="Refresh every"
                  options={[
                    { key: 10, text: "Every 10 seconds" },
                    { key: 30, text: "Every 30 seconds" },
                    { key: 60, text: "Every 1 minute" },
                    { key: 300, text: "Every 5 minutes" },
                  ]}
                />
              )}
            </Stack>
          </>
        ) : (
          <ProgressIndicator></ProgressIndicator>
        )}
      </Stack>
    </Panel>
  );
};
