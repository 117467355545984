import { AccessTokens, axiosInstance } from "./BaseService";

export type LogFilterDto = {
  id?: string;
  documentId?: string;
  receiptId?: string;
  fromDate?: string;
  toDate?: string;
  sender?: string;
  receiver?: string;
  actionName?: string;
  errorMessage?: string;
  skip?: number;
  take?: number;
};

export type LogDto = {
  id: string;
  documentId: string;
  receiptId: string;
  createdOn: string;
  senderId: string;
  receiverId: string;
  actionName: string;
  errorMessage: string;
};

export const getLogs = (tokens: AccessTokens, filter: LogFilterDto) =>
  axiosInstance
    .post(`api/logs/list`, filter, {
      headers: {
        Authorization: "Bearer " + tokens.application,
        apim: "Bearer " + tokens.apim,
      },
    })
    .then((resp) => {
      return resp.data as LogDto[];
    })
    .catch((error) => {
      console.log(error);
      return [];
    });
