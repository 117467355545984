import React, { useEffect, useState } from "react";
import { DetailsListLayoutMode, IColumn, IObjectWithKey, Selection, SelectionMode } from "@fluentui/react/lib/DetailsList";
import { ShimmeredDetailsList } from "@fluentui/react/lib/ShimmeredDetailsList";
import { MarqueeSelection } from "@fluentui/react/lib/MarqueeSelection";
import { AccessTokens } from "../../services/API/BaseService";
import { deleteDocuments, DocumentFilterDto, DocumentFullDto, getDocuments, TransferState } from "../../services/API/DocumentService";
import {
  ComboBox,
  CommandBar,
  DatePicker,
  DefaultButton,
  Dropdown,
  IComboBoxOption,
  ICommandBarItemProps,
  Icon,
  Label,
  Stack,
  TextField,
} from "@fluentui/react";
import { Text } from "@fluentui/react/lib/Text";
import { useConst } from "@fluentui/react-hooks";
import { DetailsPanel } from "./DetailsPanel";
import { DeleteDialog } from "./DeleteDialog";

const options: IComboBoxOption[] = [
  { key: 10, text: "10" },
  { key: 25, text: "25" },
  { key: 50, text: "50" },
  { key: 100, text: "100" },
  { key: 1000, text: "1k" },
  { key: 5000, text: "5k" },
  { key: 10000, text: "10k" },
  { key: 100000, text: "100k" },
];

const documentListColumns: IColumn[] = [
  {
    key: "icon",
    name: "",
    fieldName: "icon",
    minWidth: 20,
    maxWidth: 20,
    isResizable: false,
    isIconOnly: true,
    iconName: "Page",
    onRender: (item: DocumentFullDto) => <Icon iconName="TextDocumentShared" className="documentListIcon" />,
  },
  {
    key: "id",
    name: "Id",
    fieldName: "id",
    minWidth: 220,
    maxWidth: 220,
    isResizable: true,
  },
  {
    key: "createdOn",
    name: "Created On",
    fieldName: "createdOn",
    minWidth: 100,
    maxWidth: 120,
    isResizable: true,
    onRender: (item: DocumentFullDto) => !!item.createdOn && new Date(item.createdOn).toLocaleString(),
  },
  {
    key: "sender",
    name: "Sender",
    fieldName: "senderId",
    minWidth: 100,
    maxWidth: 100,
    isResizable: true,
  },
  {
    key: "receiver",
    name: "Receiver",
    fieldName: "receiverId",
    minWidth: 100,
    maxWidth: 100,
    isResizable: true,
  },
  {
    key: "documentType",
    name: "Document Type",
    fieldName: "documentType",
    minWidth: 100,
    maxWidth: 200,
    isResizable: true,
  },
  {
    key: "state",
    name: "State",
    fieldName: "state",
    minWidth: 100,
    maxWidth: 100,
    isResizable: true,
    onRender: (item: DocumentFullDto) => TransferState[item.state],
  },
  {
    key: "encryptionType",
    name: "Encryption",
    fieldName: "encryptionType",
    minWidth: 100,
    maxWidth: 100,
    isResizable: true,
  },
  {
    key: "encryptionProps",
    name: "Encryption Props",
    fieldName: "encryptionProps",
    minWidth: 100,
    maxWidth: 500,
    isResizable: true,
  },
  {
    key: "signature",
    name: "Signature",
    fieldName: "signature",
    minWidth: 100,
    maxWidth: 500,
    isResizable: true,
  },
];

type DocumentsPageProps = {
  accessTokens: AccessTokens;
};

export const DocumentsPage: React.FC<DocumentsPageProps> = ({ accessTokens }: DocumentsPageProps) => {
  const [documents, setDocuments] = useState<DocumentFullDto[]>();

  const [id, setId] = useState<string>();
  const [fromDate, setFromDate] = useState<Date>();
  const [toDate, setToDate] = useState<Date>();
  const [senderId, setSenderId] = useState<string>();
  const [receiverId, setReceiverId] = useState<string>();
  const [documentType, setDocumentType] = useState<string>();
  const [state, setState] = useState<TransferState>();
  const [encryptionType, setEncryptionType] = useState<string>();
  const [take, setTake] = useState<number>(options[0].key as number);

  const [pageNumber, setPageNumber] = useState<number>(1);

  const [selectedItems, setSelectedItems] = useState<DocumentFullDto[]>([]);
  const [detailsItem, setDetailsItem] = useState<DocumentFullDto>();
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const selection = useConst<Selection>(() => {
    const newSelection: Selection<DocumentFullDto & IObjectWithKey> = new Selection<DocumentFullDto & IObjectWithKey>({
      getKey: (item) => item.id,
      onSelectionChanged: () => setSelectedItems(newSelection.getSelection()),
    });
    return newSelection;
  });

  const refreshDocuments = () => {
    setDocuments(undefined);
    const filter: DocumentFilterDto = {
      id: id,
      fromDate: fromDate,
      toDate: toDate,
      senderId: senderId,
      receiverId: receiverId,
      documentType: documentType,
      state: state,
      encryptionType: encryptionType,
      skip: take ? take * (pageNumber - 1) : undefined,
      take: take,
    };
    const cleanFilter = Object.fromEntries(Object.entries(filter).filter(([_, v]) => v !== null && v !== undefined && v !== ""));
    getDocuments(accessTokens, cleanFilter).then(setDocuments);
  };

  const commandBarItems: ICommandBarItemProps[] = [
    {
      key: "reload",
      text: "Apply Filters",
      iconProps: { iconName: "Filter" },
      onClick: () => refreshDocuments(),
    },
    {
      key: "delete",
      text: "Delete",
      iconProps: { iconName: "Delete" },
      onClick: () => setShowDeleteDialog(true),
      disabled: !(selectedItems?.length > 0),
    },
  ];

  const deleteSelectedItems = () => {
    setDocuments(undefined);
    const ids = selectedItems.map((t) => t.id);
    deleteDocuments(accessTokens, ids).then(refreshDocuments);
  };

  useEffect(() => {
    refreshDocuments();
  }, [pageNumber, take]);

  return (
    <Stack className="adminPage">
      <div className="pageHeader">
        <Text variant="xLarge">Documents</Text>
      </div>
      <Stack horizontal tokens={{ childrenGap: 20 }}>
        <TextField
          label="Document Id"
          placeholder="00000000-0000-0000-0000-000000000000"
          value={id}
          onChange={(ev, text) => setId(text)}
          className="textGuid"
        />
        <TextField label="Sender" placeholder="CHE000000000" value={senderId} onChange={(ev, text) => setSenderId(text)}></TextField>
        <TextField label="Receiver" placeholder="CHE000000000" value={receiverId} onChange={(ev, text) => setReceiverId(text)}></TextField>
        <Dropdown
          label="State"
          selectedKey={state}
          onChange={(ev, item) => setState(item?.key as TransferState)}
          options={[
            { key: "", text: "" },
            { key: 0, text: "Pending" },
            { key: 1, text: "Sent" },
          ]}
          style={{ minWidth: "100px" }}
        />
        <TextField label="Document Type" value={documentType} onChange={(ev, text) => setDocumentType(text)}></TextField>
        <TextField label="Encryption Type" value={encryptionType} onChange={(ev, text) => setEncryptionType(text)}></TextField>
      </Stack>

      <Stack horizontal tokens={{ childrenGap: 20 }}>
        <DatePicker label="From Date" value={fromDate} onSelectDate={setFromDate as (date: Date | null | undefined) => void}></DatePicker>
        <DatePicker label="To Date" value={toDate} onSelectDate={setToDate as (date: Date | null | undefined) => void}></DatePicker>
      </Stack>

      <CommandBar items={commandBarItems} className="pageCommandBar" />

      <MarqueeSelection selection={selection as Selection} isDraggingConstrainedToRoot>
        <ShimmeredDetailsList
          items={documents || []}
          columns={documentListColumns}
          setKey="set"
          layoutMode={DetailsListLayoutMode.justified}
          selection={selection as Selection}
          selectionMode={SelectionMode.multiple}
          selectionPreservedOnEmptyClick={true}
          ariaLabelForSelectionColumn="Toggle selection"
          ariaLabelForSelectAllCheckbox="Toggle selection for all items"
          checkButtonAriaLabel="select row"
          onItemInvoked={setDetailsItem}
          enableShimmer={!documents}
          shimmerLines={5}
        />
      </MarqueeSelection>

      <Stack horizontal tokens={{ childrenGap: 5 }} padding="20px">
        <DefaultButton text="Previous" onClick={() => setPageNumber(pageNumber - 1)} disabled={!(pageNumber > 1)} />
        <DefaultButton text="Next" onClick={() => setPageNumber(pageNumber + 1)} />
        <ComboBox defaultSelectedKey={10} options={options} onChange={(ev, option, index, value) => setTake(option?.key as number)} className="pageCounter" />
        <Label>Page {pageNumber}</Label>
      </Stack>

      {showDeleteDialog && <DeleteDialog isOpen={showDeleteDialog} onDismiss={() => setShowDeleteDialog(false)} onDelete={deleteSelectedItems} />}
      {!!detailsItem && <DetailsPanel isOpen={!!detailsItem} onDismiss={() => setDetailsItem(undefined)} item={detailsItem} />}
    </Stack>
  );
};
