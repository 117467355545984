import { AccessTokens, axiosInstance } from "./BaseService";

export enum ReceiptCode {
  Success,
  TimeoutExpired,
  ValidationFailed,
  RejectedByReceiver,
  ExternalException,
}

export type ReceiptFilterDto = {
  id?: string;
  fromDate?: Date;
  toDate?: Date;
  transferId?: string;
  transferSenderId?: string;
  transferReceiverId?: string;
  code?: ReceiptCode;
  message?: string;
  skip?: number;
  take?: number;
};

export type ReceiptFullDto = {
  id: string;
  receivedDate: Date;
  transferId: string;
  transferSenderId: string;
  transferReceiverId: string;
  code: ReceiptCode;
  message: string;
};

export const getReceipts = (tokens: AccessTokens, filter: ReceiptFilterDto) => {
  return axiosInstance
    .post("api/receipts/list", filter, {
      headers: {
        Authorization: "Bearer " + tokens.application,
        apim: "Bearer " + tokens.apim,
      },
    })
    .then((resp) => {
      return resp.data as ReceiptFullDto[];
    })
    .catch((error) => {
      console.log(error);
      return [];
    });
};

export const deleteReceipts = (tokens: AccessTokens, ids: string[]) => {
  return axiosInstance
    .delete("api/receipts", {
      headers: {
        Authorization: "Bearer " + tokens.application,
        apim: "Bearer " + tokens.apim,
      },
      data: ids,
    })
    .then((resp) => {
      return resp.data;
    })
    .catch((error) => {
      console.log(error);
    });
};
