import { Checkbox, DetailsList, DetailsListLayoutMode, IColumn, IconButton, Selection, SelectionMode, Stack } from '@fluentui/react';
import React, { useMemo, useState } from 'react';
import { ParticipantSubscriptionDto } from '../services/API/ParticipantService';

type SubscriptionsListProps = {
  displayActions?: boolean;
  displayParticipantUid?: boolean;
  displaySubscriptionId?: boolean;
  onDelete?: (item: ParticipantSubscriptionDto) => void;
  onEdit?: (item: ParticipantSubscriptionDto) => void;
  selection?: Selection<ParticipantSubscriptionDto>;
  subscriptions: ParticipantSubscriptionDto[];
};


export const SubscriptionsDetailList: React.FC<SubscriptionsListProps> = ({
  displayParticipantUid,
  displaySubscriptionId,
  onDelete,
  onEdit,
  selection,
  subscriptions,
  displayActions,
}) => {
  const [isAltDataVisible, setAltDataVisible] = useState<boolean>(!displaySubscriptionId);
  const [isRootDataVisible, setRootDataVisible] = useState<boolean>(false);

  const subscriptionListColumns = useMemo(() => {
    let columns: IColumn[] = [];

    if (displayParticipantUid)
      columns.push({
        key: 'participantUID',
        name: 'Participant',
        fieldName: 'participantUID',
        minWidth: 150,
        maxWidth: 250,
        isResizable: true,
      });

    if (displaySubscriptionId)
      columns.push({
        key: 'subscriptionId',
        name: 'Subscription',
        fieldName: 'subscriptionId',
        minWidth: 150,
        maxWidth: 250,
        isResizable: true,
      });

    columns = columns.concat([
      {
        key: 'certificateThumbprint',
        name: 'Thumbprint',
        fieldName: 'certificateThumbprint',
        minWidth: 250,
        maxWidth: 400,
        isResizable: true,
      },
      {
        key: 'certificateExpiredAfter',
        name: 'Expired Date',
        fieldName: 'certificateExpiredAfter',
        minWidth: 150,
        maxWidth: 250,
        isResizable: true,
      },
    ]);

    if (isAltDataVisible)
      columns = columns.concat([
        {
          key: 'altCertificateThumbprint',
          name: 'Alternate Thumbprint',
          fieldName: 'altCertificateThumbprint',
          minWidth: 250,
          maxWidth: 400,
          isResizable: true,
        },
        {
          key: 'altCertificateExpiredAfter',
          name: 'Alternate Expired Date',
          fieldName: 'altCertificateExpiredAfter',
          minWidth: 150,
          maxWidth: 250,
          isResizable: true,
        },
      ]);

    if (isRootDataVisible)
      columns = columns.concat([
        {
          key: 'certificateIssuer',
          name: 'Root Cert Issuer',
          fieldName: 'certificateIssuer',
          minWidth: 150,
          maxWidth: 250,
          isResizable: true,
        },

        {
          key: 'certificateRootThumbprint',
          name: 'Root Cert Thumbprint',
          fieldName: 'certificateRootThumbprint',
          minWidth: 150,
          maxWidth: 250,
          isResizable: true,
        },

        {
          key: 'certificateSubject',
          name: 'Root Cert Subject',
          fieldName: 'certificateSubject',
          minWidth: 150,
          maxWidth: 250,
          isResizable: true,
        },
      ]);

    if (displayActions)
      columns.push({
        key: 'actions',
        name: '',
        minWidth: 100,
        maxWidth: 100,
        isIconOnly: true,
        iconName: 'LightningBolt',

        onRender: (item: ParticipantSubscriptionDto) => (
          <>
            {onEdit && (
              <IconButton
                iconProps={{
                  iconName: 'Edit',
                }}
                onClick={(evt) => {
                  evt.stopPropagation();
                  onEdit(item);
                }}
              />
            )}
            {onDelete && (
              <IconButton
                iconProps={{
                  iconName: 'Delete',
                }}
                onClick={(evt) => {
                  evt.stopPropagation();
                  onDelete(item);
                }}
              />
            )}
          </>
        ),
      });
    return columns;
  }, [onDelete, onEdit, displayParticipantUid, displaySubscriptionId, isRootDataVisible, isAltDataVisible, displayActions]);

  return (
    <>
      <Stack horizontal gap={16} style={{ margin: '1rem 0' }} horizontalAlign="end">
        <Checkbox label="Display Alternate Data" checked={isAltDataVisible} onChange={() => setAltDataVisible((v) => !v)} />
        <Checkbox label="Display Root Certificate Data" checked={isRootDataVisible} onChange={() => setRootDataVisible((v) => !v)} />
      </Stack>
      <DetailsList
        items={subscriptions || []}
        columns={subscriptionListColumns}
        layoutMode={DetailsListLayoutMode.justified}
        selectionMode={selection ? SelectionMode.multiple : SelectionMode.none}
        selection={selection as any}
        selectionPreservedOnEmptyClick={true}
        styles={{
          root: {
            marginBottom: '1rem',
          },
        }}
      />
    </>
  );
};
