import forge from "node-forge";

export type ClientCertificate = {
  base64content: string;
  thumbprint: string;
  expiredAfter: Date;
};

export const generateClientCertificate = (uid: string | undefined) => {
  const pki = forge.pki;

  // generate a keypair and create an X.509v3 certificate
  const keys = pki.rsa.generateKeyPair(4096);
  const cert = pki.createCertificate();
  cert.publicKey = keys.publicKey;

  cert.serialNumber = "01";
  cert.validity.notBefore = new Date();
  cert.validity.notAfter = new Date();
  cert.validity.notAfter.setFullYear(cert.validity.notBefore.getFullYear() + 1);
  const attrs = [
    {
      name: "commonName",
      value: uid ?? "uid",
    },
    {
      name: "countryName",
      value: "CH",
    },
    {
      name: "organizationName",
      value: "Ardevia",
    },
    {
      shortName: "OU",
      value: "Ardevia",
    },
  ];
  cert.setSubject(attrs);
  cert.setIssuer(attrs);
  cert.setExtensions([
    {
      name: "basicConstraints",
      cA: true,
    },
    {
      name: "keyUsage",
      keyCertSign: false,
      digitalSignature: true,
      nonRepudiation: false,
      keyEncipherment: true,
      dataEncipherment: true,
    },
    {
      name: "extKeyUsage",
      serverAuth: true,
      clientAuth: true,
      codeSigning: false,
      emailProtection: false,
      timeStamping: true,
    },
    {
      name: "nsCertType",
      client: true,
      server: true,
      email: true,
      objsign: true,
      sslCA: true,
      emailCA: true,
      objCA: true,
    },
    {
      name: "subjectAltName",
      altNames: [
        {
          type: 6, // URI
          value: "https://api.easx.ch",
        },
      ],
    },
    {
      name: "subjectKeyIdentifier",
    },
  ]);
  // self-sign certificate sha256
  cert.sign(keys.privateKey, forge.md.sha256.create());
  // self-sign certificate sha1
  //cert.sign(keys.privateKey);

  const md = forge.md.sha1.create();
  md.update(forge.asn1.toDer(forge.pki.certificateToAsn1(cert)).getBytes());
  const hex = md.digest().toHex();

  // generate a p12 that can be imported by Chrome/Firefox/iOS
  // (requires the use of Triple DES instead of AES)
  const p12Asn1 = forge.pkcs12.toPkcs12Asn1(keys.privateKey, cert, null, {
    algorithm: "3des",
  });

  // base64-encode p12
  const p12Der = forge.asn1.toDer(p12Asn1).getBytes();
  const p12b64 = forge.util.encode64(p12Der);

  const result: ClientCertificate = {
    base64content: p12b64,
    thumbprint: hex.toUpperCase(),
    expiredAfter: cert.validity.notAfter,
  };
  return result;
};
